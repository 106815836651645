import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance';

export default function Member() {

    async function linkEbayAccount() {
        try {
            const response = await axiosInstance.get('/v1/crew/external/ebay/getSessionUrl');
            const sessionId = new URL(response.data.sessionUrl).searchParams.get('SessID');
            // update sessionId in the member account
            await axiosInstance.post('/v1/crew/external/ebay/updateEbayMeta', { sessionId });
            // redirect to eBay auth URL
            const sessionUrl = response.data.sessionUrl;
            window.location.href = sessionUrl;
        } catch (err) {
            console.error('Error linking eBay account:', err);
        }
    }

    async function handleIncomingAuth() {
        // search for tknexp in query
        const params = new URLSearchParams(window.location.search);
        const authExpiry = params.get('tknexp');
        if (authExpiry) {   
            // proof that the user has authenticated
            await axiosInstance.get('/v1/crew/external/ebay/fetchSessionToken').then((response) => {
                console.log(response.data);
            }).catch((err) => {
                console.error('Error fetching eBay session token:', err);
            });
        }
    
    }

    useEffect(() => {
        handleIncomingAuth();
    }, []);

    return (
        <div>
            <button
                onClick={linkEbayAccount}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Link eBay Account
            </button>
        </div>
    );
}
