import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import axiosInstance from '../../axiosInstance';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { Search, X } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import ebayIcon from '../../Assets/Images/ebay_icon.svg';
import facebookIcon from '../../Assets/Images/facebook_icon.svg';
import memberlogo from '../../Assets/Images/memberlogo.webp';
import Chip from '../../Components/Chip/Chip';
import Button from '../../Components/Button/Button';
import Loading from '../Loading/Loading';
import DropdownButton from '../../Components/DropdownButton/DropdownButton';

import ConditionCell from '../../Components/TableCells/ConditionCell';

export default function ViewListings() {
  const navigate = useNavigate();

  const [rowData, setRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // Zero-based index
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const isMounted = useRef(true); // Track if the component is mounted

  // Ref to store the current width of the thumbnail column
  const thumbnailColumnWidthRef = useRef(120); // Default width

  // Custom rendering functions
  const renderThumbnail = (value, title) => (
    <div className="flex items-center h-full justify-center">
      <div
        className="flex items-center justify-center bg-gray-100 border border-gray-300 rounded overflow-hidden"
        style={{ width: '100%', aspectRatio: '1/1' }} // Fill the cell's width and height
      >
        <img
          src={
            value
              ? value.includes('gavelbase.s3')
                ? value.replace('/images/', '/thumbnails/')
                : value
              : 'https://via.placeholder.com/96'
          }
          alt={`Thumbnail for ${title}`}
          className="object-cover w-full h-full"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = 'https://via.placeholder.com/96';
          }}
        />
      </div>
    </div>
  );

  const renderLotTitle = (value, lotNumber) => (
    <div
      style={{ overflowX: 'hidden', whiteSpace: 'normal', wordWrap: 'break-word' }}
      className="h-full flex justify-center flex-col"
    >
      <p className="leading-tight font-bold">{lotNumber}</p>
      <p className="leading-tight">{value}</p>
    </div>
  );


  const renderStatus = (status) => {
    switch (status) {
      case 'Idle':
        return (
          <div className="flex items-center h-full">
            <Chip text={status} />
          </div>
        );
      case 'Active':
        return (
          <div className="flex items-center h-full">
            <Chip text={status} color="green" />
          </div>
        );
      case 'Sold':
        return (
          <div className="flex items-center h-full">
            <Chip text={status} color="red" />
          </div>
        );
      case 'Completed':
        return (
          <div className="flex items-center h-full">
            <Chip text={status} color="blue" />
          </div>
        );
      default:
        return status;
    }
  };

  const renderPlatforms = (listings, type) => {
    if (listings && listings.length > 0) {
      return (
        <div className='flex flex-col justify-center items-start h-full'>
          <p className="text-gray-500 text-sm">{type}</p>
          <div className="flex justify-evenly gap-2">
            
            {listings.map((listing, index) => (
              <>
                <div
                  key={index}
                  className="flex flex-col items-center justify-evenly h-full leading-tight min-w-[80px]"
                >
                  <p 
                    // green for Sold, slashed for Unsold, No color for Active
                    className={`font-semibold text-lg ${listing.status === 'Sold' ? 'text-green-500' : listing.status === 'Unsold' ? 'line-through text-gray-500' : ''}`}
                    title={listing.status}>${(listing.currentPrice / 100).toFixed(2)}
                  </p>
                  {listing.platform === 'Ebay' && (
                    <a
                      href={`https://ebay.com/itm/${listing.ebayItemId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={ebayIcon} className="h-5 mt-0" alt="Ebay Icon" />
                    </a>
                  )}
                  {listing.platform === 'Facebook' && (
                    <a
                      href={`https://www.facebook.com/marketplace/item/${listing.facebookItemId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={facebookIcon} className="h-4" alt="Facebook Icon" />
                    </a>
                  )}
                  {listing.platform === 'Member' && (
                    <a
                      href={`https://5dollarauctions.com/lot/${listing.lotId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={memberlogo} className="h-5" alt="Member Icon" />
                    </a>
                  )}
                </div>
                {
                  index < listings.length - 1 && <div className="h-full w-0.5 bg-gray-300" />
                }
              </>
            ))}

          </div>
        </div>
      );
    }
    return 'N/A';
  };

  const renderLocation = (location) => {
    return (
      <div className="flex items-center h-full">
        <p className="font-bold">{location?.label || 'N/A'}</p>
      </div>
    );
  };

  // Column definitions for AG Grid
  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Thumbnail',
        field: 'thumbnail',
        width: 120, // Initial width
        resizable: true, // Allow resizing
        cellRenderer: (params) => renderThumbnail(params.value, params.data.title),
      },
      {
        headerName: 'Lot Number/Title',
        field: 'title',
        flex: 1,
        cellRenderer: (params) => renderLotTitle(params.value, params.data.lotNumber),
      },
      {
        headerName: 'Status',
        filter: true,
        field: 'status',
        
        flex: 1,
        cellRenderer: (params) => renderStatus(params.value),
      },
      {
        headerName: 'Condition',
        field: 'condition',
        flex: 1,
        cellRenderer: (params) => <ConditionCell conditionObject={params.value} />,
      },
      {
        headerName: 'Platforms',
        field: 'listings',
        flex: 2,
        cellRenderer: (params) => renderPlatforms(params.value, params.data.listings[0].type),
      },
      {
        headerName: 'Location',
        field: 'location',
        flex: 1,
        cellRenderer: (params) => renderLocation(params.value),
      },
      {
        headerName: 'Actions',
        field: 'actions',
        width: 120,
        sortable: false,
        // allow overflow on this cell
        cellStyle: { overflow: 'visible' },
        cellRenderer: (params) => (
          <div className="flex items-center justify-end h-full">
            <DropdownButton 
              text="Edit" 
              size="sm" 
              onClick={() => alert('navigate to edit listing screen')}
              className="flex items-center justify-center gap-x-1.5 bg-indigo-600 text-white font-semibold shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md" 
              dropdownOptions={[
                { label: 'Edit Lot', onClick: () => navigate(`./Edit/${params.data.id}`) },
              ]}
            />
          </div>
        ),
      },
    ],
    []
  );

  // Define a custom row height to match the thumbnail column's width
  const getRowHeight = () => {
    return thumbnailColumnWidthRef.current;
  };

  // Custom Search Component
  const CustomSearchRender = ({ searchText, onSearch, onClear }) => {
    const [text, setText] = useState(searchText || '');

    useEffect(() => {
      setText(searchText || '');
    }, [searchText]);

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onSearch(text);
      }
    };

    const handleSearchClick = () => {
      onSearch(text);
    };

    const handleClear = () => {
      setText('');
      onClear();
    };

    return (
      <div className="flex items-center space-x-2">
        <div className="relative">
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Search"
            className="block w-full rounded-md border-gray-300 pl-10 pr-3 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
        </div>
        <Button onClick={handleSearchClick} variant="primary">
          Search
        </Button>
        <Button onClick={handleClear} variant="secondary">
          <X className="h-5 w-5" />
        </Button>
      </div>
    );
  };

  // Fetch data from the server
  const fetchData = useCallback(async () => {
    setLoading(true);
    const offset = page * pageSize;
    const count = pageSize;

    try {
      const response = await axiosInstance.post('/v1/crew/listing/keyword-search', {
        keyword: searchText,
        offset: offset,
        count: count,
        sort: { createdAt: -1 },
      });

      if (response.status === 200) {
        const combinedListings = response.data.results;
        const formattedData = combinedListings.map((listing) => ({
          id: listing._id,
          // lot id must be inside each listing
          ...listing,
          thumbnail: listing.thumbnail || 'https://via.placeholder.com/96',
          location: listing.location || { label: 'N/A' },
          lotNumber: listing.lotNumber || 'N/A', // Ensure lotNumber is included
          condition: listing.condition || null, // Include condition field
        }));
        if (isMounted.current) {
          setRowData(formattedData);
          setTotalCount(response.data.totalSearchCount);
        }
      } else {
        console.warn('Unexpected response status:', response.status);
        if (isMounted.current) {
          setRowData([]);
        }
      }
    } catch (error) {
      console.error('Error fetching listings:', error);
      if (isMounted.current) {
        setRowData([]);
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  }, [page, pageSize, searchText]);

  // Fetch data when page, pageSize, or searchText change
  useEffect(() => {
    isMounted.current = true; // Set the mounted flag to true
    fetchData();
    return () => {
      isMounted.current = false; // Cleanup function to set the mounted flag to false
    };
  }, [fetchData]);

  // AG Grid configuration
  const gridOptions = {
    columnDefs: columnDefs,
    rowData: rowData,
    pagination: false, // Disable AG Grid's built-in pagination
    domLayout: 'autoHeight',
    getRowHeight: getRowHeight, // Use custom row height function
    overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplate: loading
      ? '<span class="ag-overlay-loading-center">Loading...</span>'
      : '<span class="ag-overlay-no-rows-center">Sorry, no matching records found</span>',
    onGridReady: (params) => {
      // Size columns to fit the grid
      params.api.sizeColumnsToFit();
    },
    onColumnResized: (params) => {
      if (params.column && params.column.getColId() === 'thumbnail') {
        thumbnailColumnWidthRef.current = params.column.getActualWidth();
        params.api.resetRowHeights(); // Recalculate row heights
        
      }
    },
  };

  // Handle page changes
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Handle page size changes
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0); // Reset to first page
  };

  // Handle search
  const handleSearch = (text) => {
    setSearchText(text);
    setPage(0); // Reset to first page
  };

  // Handle clear search
  const handleClearSearch = () => {
    setSearchText('');
    setPage(0);
  };

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <div className="flex flex-col h-full bg-gray-50 min-h-screen">
      {/* Header Section */}
      <div className="bg-white shadow border-b p-6">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Listings
            </h2>
          </div>
          <div className="mt-5 flex lg:ml-4 lg:mt-0 space-x-4">
            <CustomSearchRender
              searchText={searchText}
              onSearch={handleSearch}
              onClear={handleClearSearch}
            />
            <Link to="./Create" className="inline-flex items-center px-4 py-2 text-sm font-semibold text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-md">
              Create Listing
            </Link>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col flex-1">
        <div className="ag-theme-material w-full" style={{ flex: '1 1 auto', overflow: 'hidden' }}>
          <AgGridReact {...gridOptions}  />
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center p-3">
          <div>
            <span className="text-sm text-gray-700">
              Page {page + 1} of {totalPages}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(Math.max(page - 1, 0))}
              disabled={page === 0}
              className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                page === 0
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-500'
              } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(Math.min(page + 1, totalPages - 1))}
              disabled={page + 1 >= totalPages}
              className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                page + 1 >= totalPages
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-500'
              } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Next
            </button>
            <select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              className="rounded-md border border-gray-300 bg-white py-2 px-3 pr-8 text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  {size} / page
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Loading Overlay */}
        {loading && <Loading />}
      </div>
    </div>
  );
}
