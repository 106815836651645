import React from 'react';

const ConditionCell = ({ conditionObject }) => {
  if (conditionObject && typeof conditionObject === 'object') {
    const condition =
      conditionObject.value && typeof conditionObject.value === 'object'
        ? conditionObject.value
        : conditionObject;

    return (
      <div className="flex flex-col h-full justify-center">
        <p className="font-bold">{condition.name || 'N/A'}</p>
        <p className="text-xs">{condition.conditionDescription || 'No description'}</p>
      </div>
    );
  }
  return 'N/A';
};

export default ConditionCell;
