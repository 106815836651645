import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import CurrencyInput from "react-currency-input-field";
import Button from "../../Components/Button/Button";
import axiosInstance from "../../axiosInstance";
import { toast } from "react-toastify";

// Utility for combining class names
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Payment method options
const paymentMethodOptions = [
  { label: "Cash", value: "Cash" },
  { label: "Credit Card", value: "Stripe" },
];

export default function PaymentModal({ isOpen, onClose = () => {}, orderId }) {
  // State for order totals, payment method, submission, order data, and (for cash) cash received.
  const [amount, setAmount] = useState(""); // This will hold the static order total (in dollars)
  const [method, setMethod] = useState("Credit Card");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [cashReceived, setCashReceived] = useState(""); // For cash payments

  const closeModal = () => {
    onClose(false);
  };

  // Compute order total from line items and order ledger charges (returns a string in dollars)
  const getOrderTotal = (order) => {
    // 1) Sum of lineItems charges (assume amounts are in cents)
    const lineItemsSum = order.lineItems?.reduce((acc, lineItem) => {
      const itemTotal = lineItem.charges.reduce((sum, charge) => sum + charge.amount, 0);
      return acc + itemTotal;
    }, 0) || 0;
  
    // 2) Sum of orderLedger charges
    const orderLedgerSum = order.orderLedger?.charges?.reduce((acc, charge) => acc + charge.amount, 0) || 0;
  
    // 3) Total in cents
    const totalInCents = lineItemsSum + orderLedgerSum;
  
    // 4) Convert to dollars and fix to 2 decimals
    return (totalInCents / 100).toFixed(2);
  };

  // Compute total payments made (assume payment amounts are in cents)
  const getPaymentsTotal = (order) => {
    return order.payments?.reduce((acc, payment) => acc + payment.amount, 0) || 0;
  };

  // Given the order total and payments, compute balance due (in dollars)
  const computeBalanceDue = () => {
    const orderTotal = parseFloat(getOrderTotal(orderData));
    const paymentsTotal = getPaymentsTotal(orderData) / 100;
    return (orderTotal - paymentsTotal).toFixed(2);
  };

  // Compute change due for cash payments: cash received minus balance due
  const computeChangeDue = () => {
    const balanceDue = parseFloat(computeBalanceDue());
    const received = parseFloat(cashReceived);
    if (!isNaN(received) && received >= balanceDue) {
      return (received - balanceDue).toFixed(2);
    }
    return "0.00";
  };

  // Handle form submission for adding payment
  const handleSubmit = async (e) => {
    e.preventDefault();
    let paymentAmount;

    // If payment method is Cash, use the balance due as the amount (recorded payment equals balance due)
    if (method === "Cash") {
      paymentAmount = Math.round(parseFloat(computeBalanceDue()) * 100);
    } else {
      // For Credit Card, parse the entered amount from the input
      const parsedAmount = parseFloat(amount);
      if (isNaN(parsedAmount) || parsedAmount <= 0) {
        toast.error("Please enter a valid amount.");
        return;
      }
      paymentAmount = Math.round(parsedAmount * 100);
    }

    setIsSubmitting(true);

    try {
      await axiosInstance.post(`/v1/crew/order/add-payment`, {
        amount: paymentAmount,
        method, // "Cash" or "Stripe"
        orderId,
      });
      toast.success("Payment added successfully!");
      onClose(false);
    } catch (error) {
      toast.error(error.response?.data || "Failed to add payment.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Fetch order data when the modal opens
  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const { data } = await axiosInstance.get(`/v1/crew/order/getOrder/${orderId}`);
        setOrderData(data.order);
        // Set static order total as the initial amount (for credit card mode)
        setAmount(getOrderTotal(data.order));
        console.log(data);
        console.log(getOrderTotal(data.order));
      } catch (error) {
        toast.error(error.response?.data || "Failed to fetch order data.");
      }
    };

    if (isOpen && orderId) {
      fetchOrderData();
    }
  }, [orderId, isOpen]);

  // Determine the selected payment method object
  const selectedMethodObj =
    paymentMethodOptions.find((opt) => opt.label === method) || paymentMethodOptions[0];

  // Compute values for order summary display
  const orderTotal = getOrderTotal(orderData);
  const balanceDue = computeBalanceDue();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          {/* Trick browser into centering the modal. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Payment for Order #{orderId}
              </Dialog.Title>

              {/* Order Summary Section */}
              <div className="mt-4 mb-6 border p-4 rounded-md">
                <h4 className="text-md font-semibold mb-2">Order Summary</h4>
                {orderData.lineItems && orderData.lineItems.length > 0 ? (
                  <div className="space-y-2">
                    {orderData.lineItems.map((item, idx) => (
                      <div key={idx} className="border-b pb-2">
                        <div className="font-semibold">{item.title}</div>
                        <div className="text-sm space-y-1">
                          {item.charges.map((charge, i) => (
                            <div key={i} className="flex justify-between">
                              <span>{charge.name}:</span>
                              <span>${(charge.amount / 100).toFixed(2)}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-sm text-gray-600">No line items available.</p>
                )}
                <div className="mt-2 flex justify-between font-bold">
                  <span>Order Total: ${orderTotal}</span>
                  <span>Balance Due: ${balanceDue}</span>
                </div>
              </div>

              {/* Payments Made Section */}
              <div className="mb-6 border p-4 rounded-md">
                <h4 className="text-md font-semibold mb-2">Payments Made</h4>
                {orderData.payments && orderData.payments.length > 0 ? (
                  <div className="space-y-2">
                    {orderData.payments.map((payment, idx) => (
                      <div key={idx} className="flex justify-between text-sm">
                        <span>{payment.method}</span>
                        <span>${(payment.amount / 100).toFixed(2)}</span>
                        <span className="text-xs text-gray-500">
                          {new Date(payment.createdAt).toLocaleDateString()} {payment.status}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-sm text-gray-600">No payments have been made.</p>
                )}
              </div>

              {/* Payment Form */}
              <form onSubmit={handleSubmit} className="mt-4">
                <div className="mb-4">
                  <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                    {method === "Cash" ? "Cash Received" : "Payment Amount"}
                  </label>
                  <CurrencyInput
                    id="amount"
                    name="amount"
                    prefix="$"
                    placeholder="Enter amount"
                    decimalsLimit={2}
                    value={method === "Cash" ? cashReceived : amount}
                    onValueChange={(value) => {
                      if (method === "Cash") {
                        setCashReceived(value || "");
                      } else {
                        setAmount(value || "");
                      }
                    }}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required
                  />
                </div>

                {method === "Cash" && (
                  <div className="mb-4 border p-4 rounded-md">
                    <h4 className="text-md font-semibold mb-2">Change Calculator</h4>
                    <div className="font-semibold">
                      Change Due: ${computeChangeDue()}
                    </div>
                  </div>
                )}

                {/* Payment Method Selector */}
                <div className="mb-4">
                  <label htmlFor="method" className="block text-sm font-medium text-gray-700">
                    Payment Method
                  </label>
                  <Listbox value={selectedMethodObj} onChange={(selectedObj) => setMethod(selectedObj.label)}>
                    {({ open }) => (
                      <div className="relative mt-1">
                        <Listbox.Button
                          className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none sm:text-sm"
                        >
                          <span className="block truncate">{selectedMethodObj.label}</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>
                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                          >
                            {paymentMethodOptions.map((option) => (
                              <Listbox.Option
                                key={option.value}
                                className={({ active }) =>
                                  classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={option}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected ? "font-semibold" : "font-normal",
                                        "block truncate"
                                      )}
                                    >
                                      {option.label}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          "absolute inset-y-0 right-0 flex items-center pr-4",
                                          active ? "text-indigo-600" : "text-indigo-600"
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    )}
                  </Listbox>
                </div>

                {/* Submit and Cancel buttons */}
                <div className="flex justify-end">
                  <Button variant="secondary" onClick={closeModal} className="mr-2" type="button">
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Submit Payment"}
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
