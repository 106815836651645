import React, { useState, useEffect, useRef, useCallback } from 'react';
import axiosInstance from '../../axiosInstance';
import TextInput from '../../Components/TextInput/TextInput';
import { toast } from 'react-toastify';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { BarLoader } from 'react-spinners';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ImgsViewer from 'react-images-viewer';
import DateTimeInput from '../DateTimeInput/DateTimeInput';
import Toggle from '../Toggle/Toggle';
import Button from '../Button/Button';
import ebayIcon from '../../Assets/Images/ebay_icon.svg';
import memberlogo from '../../Assets/Images/memberlogo.webp';
import CurrencyInput from 'react-currency-input-field'; 
import { BadgeCheck } from 'lucide-react';

dayjs.extend(relativeTime);


const ListItem = ({ lotId, displayLot, onSubmit, mode = "Fixed Price", typeTabs = false }) => {
  const [listingLoading, setListingLoading] = useState(false);
  const [lotDetails, setLotDetails] = useState(null);
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [requiredSpecifics, setRequiredSpecifics] = useState([]);
  const [startTime, setStartTime] = useState(new Date().toISOString());
  const [endTime, setEndTime] = useState( new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString());
  const [platforms, setPlatforms] = useState(["Ebay", "Member"]);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currImg, setCurrImg] = useState(0);
  const [scheduled, setScheduled] = useState(false);
  const [newListing, setNewListing] = useState({
    fixedPrice: 500, length: '', width: '', height: '', weight: '',
    isFlatRate: true, flatRatePrice: 0, listed: false,
    categoryId: '', lotId: '', specifics: {},
  });
  const [selectedMode, setSelectedMode] = useState(mode);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const specificsMapping = {
    Brand: 'brand',
    Model: 'model',
    UPC: 'upc',
    MPN: 'mpn',
    Type: 'type',
    Material: 'material',
    Color: 'color',
    Size: 'size',
    Height: 'height',
    Width: 'width',
    Depth: 'depth',
  };

  const handleResize = useCallback(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const fetchLotDetails = async () => {
      try {
        const response = await axiosInstance.get('/v1/crew/lot/info', { params: { lotId } });
        if (response.data.lot) {
          setLotDetails(response.data.lot);
          setNewListing((prev) => ({ ...prev, lotId: response.data.lot._id }));
          return response.data.lot;
        }
      } catch (err) {
        console.error('Error fetching lot info:', err);
      }
    };

    const getCategorySuggestions = async (searchString) => {
      try {
        const response = await axiosInstance.post(
          '/v1/crew/external/ebay/getCategorySuggestions',
          { query: searchString }
        );
        if (response.data.suggestions) {
          return response.data.suggestions;
        }
      } catch (err) {
        console.error('Error fetching category suggestions:', err);
      }
    };

    fetchLotDetails().then((lot) => {
      if (lot) {
        getCategorySuggestions(lot.title).then((categories) => {
          if (categories) {
            setCategorySuggestions(categories);
            setNewListing((prev) => ({ ...prev, categoryId: categories[0].categoryId }));
          }
        }).catch((err) => {
          console.error('Error fetching category suggestions:', err);
        });
      }
    }).catch((err) => {
      console.error('Error fetching lot details:', err);
    });
  }, [lotId]);

  useEffect(() => {
    const fetchRequiredSpecifics = async () => {
      if (newListing.categoryId) {
        try {
          const response = await axiosInstance.post(
            '/v1/crew/external/ebay/getItemSpecifics',
            { categoryId: newListing.categoryId }
          );
          if (response.data.requiredAspects) {
            setRequiredSpecifics(response.data.requiredAspects);
          }
        } catch (err) {
          console.log('Error fetching required specifics:', err);
        }
      }
    };
    fetchRequiredSpecifics();
  }, [newListing.categoryId]);

  useEffect(() => {
    if (requiredSpecifics.length > 0 && lotDetails) {
      setNewListing((prev) => {
        const updatedSpecifics = { ...prev.specifics };
        requiredSpecifics.forEach((specific) => {
          const detailKey = specificsMapping[specific];
          if (detailKey) {
            const detailValue = lotDetails.details ? lotDetails.details[detailKey] : null;
            if (detailValue && !updatedSpecifics[specific]) {
              updatedSpecifics[specific] = detailValue;
            }
          }
        });
        return { ...prev, specifics: updatedSpecifics };
      });
    }
  }, [requiredSpecifics, lotDetails]);

  useEffect(() => {
    console.log(newListing.fixedPrice);
  }, [newListing.fixedPrice]);

  const handleCheckboxChange = () => {
    setNewListing((prev) => ({ ...prev, isFlatRate: !prev.isFlatRate }));
  };

  const handleListingAction = () => {
    setListingLoading(true);
    axiosInstance.post('/v1/crew/listing/create', {
      lotId: newListing.lotId,
      fixedPrice: newListing.fixedPrice,
      platforms: (selectedMode === "Fixed Price" ? platforms : ["Member"]),
      startTime: startTime,
      endTime: endTime,
      type: selectedMode,
      categoryId: newListing.categoryId,
      specifics: newListing.specifics,
      isFlatRate: newListing.isFlatRate,
      flatShippingCost: newListing.flatRatePrice,
      dimensions: newListing.isFlatRate ? null : {
        length: newListing.length,
        width: newListing.width,
        height: newListing.height,
        weight: newListing.weight,
      },
    }).then((response) => {
      setNewListing((prev) => ({ ...prev, listed: true }));
      setListingLoading(false);
      onSubmit && onSubmit(response.data);
      toast.success('Listing created successfully!');
    }).catch((err) => {
      console.log(err);
      setListingLoading(false);
      toast.error('Failed to create listing.');
    });
  };

  if (!lotDetails) {
    return (
      <div className="flex items-center justify-center h-40">
        <p className="text-gray-500">Loading lot details...</p>
      </div>
    );
  }

  const images = lotDetails.images.map((src) => ({ src }));

  const layoutStyle = {
    flexDirection: containerWidth < 768 ? 'column' : 'row',
    gridTemplateColumns: containerWidth < 768 ? '1fr' : containerWidth < 1024 ? '1fr 1fr' : '1fr 1fr 1fr 1fr',
  };

  return (
    <div ref={containerRef} className="container bg-white shadow rounded-lg p-3">
      {typeTabs && (
        <div className="flex justify-start mb-4">
          <button
            className={`px-4 py-2 ${selectedMode === 'Auction' ? 'bg-indigo-600 rounded text-white' : ''}`}
            onClick={() => setSelectedMode('Auction')}
          >
            Auction
          </button>
          <button
            className={`px-4 py-2 ${selectedMode === 'Fixed Price' ? 'bg-indigo-600 rounded text-white' : ''}`}
            onClick={() => setSelectedMode('Fixed Price')}
          >
            Fixed Price
          </button>
        </div>
      )}

      <div className="flex flex-col md:flex-row gap-2 w-full justify-between" style={layoutStyle}>
        {displayLot && (
          <div className="flex flex-col cq-lg:flex-row cq-lg:items-center">
            <div className="flex gap-2 mb-4 cq-lg:mb-0 cq-lg:mr-6">
              {lotDetails.images.slice(0, 4).map((src, idx) => (
                <div key={idx} className="w-20 h-20 bg-gray-100 rounded overflow-hidden flex items-center justify-center cursor-pointer" onClick={() => { setCurrImg(idx); setViewerIsOpen(true); }} >
                  <img className="object-contain h-full" src={src.includes('gavelbase.s3') ? src.replace('/images/', '/thumbnails/') : src} alt={`Lot Image ${idx + 1}`} />
                </div>
              ))}
              <div className="w-20 h-20 bg-gray-100 rounded flex items-center justify-center text-gray-500 cursor-pointer"> Edit </div>
            </div>
            <div className="flex-1">
              <h2 className="text-lg font-semibold text-gray-800 mb-1">{lotDetails.title}</h2>
              <p className="text-sm text-gray-500 mb-2">Lot Number: <span className="font-medium">{lotDetails.lotNumber}</span></p>
              <p className="text-sm text-gray-500 mb-2">Model: <span className="font-medium">{lotDetails.details?.model}</span></p>
              <p className="text-sm text-gray-500 mb-2">Condition:<span className="font-medium">{lotDetails.condition?.name}</span></p>
              <p className="text-sm text-gray-500">{lotDetails.conditionDescription}</p>
              <div className="mt-3">
                <button onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(lotDetails.details?.model || lotDetails.title || '');
                  toast.info('Query copied to clipboard!');
                }} className="text-blue-600 hover:underline mr-4">Copy</button>
                <button onClick={(e) => {
                  e.preventDefault();
                  const ebaySoldUrl = `https://www.ebay.com/sch/i.html?_nkw=${encodeURIComponent(lotDetails.title || '')}&_ipg=100&LH_Sold=1`;
                  window.open(ebaySoldUrl, '_blank', 'noopener,noreferrer,width=1200,height=600');
                }} className="text-blue-600 hover:underline">Search</button>
              </div>
            </div>
          </div>
        )}

        {selectedMode === "Fixed Price" && (
          <div className='flex flex-col gap-1'>
            <p className="font-semibold text-gray-800 mb-2">Select Platforms</p>
            <div className="flex md:grid grid-cols-2 gap-2 md:gap-4 ">
              <button className={`relative bg-white flex items-center p-2 justify-center rounded ring-1 ring-gray-300 transition-colors ${platforms.includes("Ebay") ? "ring-green-200 bg-green-50" : ""}`}
                style={{ aspectRatio: "2 / 1", maxHeight: '100px' }}
                onClick={() => { platforms.includes("Ebay") ? setPlatforms(platforms.filter((item) => item !== "Ebay")) : setPlatforms([...platforms, "Ebay"]); }}>
                <img src={ebayIcon} alt="Ebay" className="w-full max-h-full object-contain" />
                {platforms.includes("Ebay") && (<BadgeCheck className="absolute -top-2 -right-2 h-6 w-6 text-green-500" />)}
              </button>
              <button className={`relative bg-white flex items-center p-2 justify-center rounded ring-1 ring-gray-300 transition-colors ${platforms.includes("Member") ? "ring-green-200 bg-green-50" : ""}`}
                style={{ aspectRatio: "2 / 1", maxHeight: '100px' }}
                onClick={() => { platforms.includes("Member") ? setPlatforms(platforms.filter((item) => item !== "Member")) : setPlatforms([...platforms, "Member"]); }}>
                <img src={memberlogo} alt="Member" className="max-w-full max-h-full object-contain" />
                {platforms.includes("Member") && (<BadgeCheck className="absolute -top-2 -right-2 h-6 w-6 text-green-500" />)}
              </button>
            </div>
          </div>
        )}

      </div>

      <div className={displayLot && "mt-6 border-t pt-6"}>
        {selectedMode === "Auction" && (
          <div className="grid grid-cols-1 cq-md:grid-cols-2 cq-lg:grid-cols-4">
            <div className='flex flex-col gap-2'>
              <label className="block text-sm font-medium text-gray-700">Starting Price</label>
              <CurrencyInput
              id="starting-price"
              name="startingPrice"
              placeholder="Enter amount"
              defaultValue={newListing.fixedPrice / 100}
              decimalsLimit={2}
              onValueChange={(value) => {
                const cents = Math.round(parseFloat(value || 0) * 100);
                setNewListing((prev) => ({ ...prev, fixedPrice: cents }));
              }}
              prefix="$"
              className="block w-full pl-2 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            />
            </div>
            <div className='flex items-center gap-2 justify-between mt-2'>
              <p className="font-semibold text-gray-800 mb-2">Schedule Auction {!scheduled && "(Starts Now)"}</p>
              <Toggle checked={scheduled} onChange={() => setScheduled(!scheduled)} />
            </div>
            {
              scheduled && (
                <>
                  <DateTimeInput label={'Start Time'} onChange={(e) => { setStartTime(e) }} value={startTime} />
                  <div className='flex justify-start gap-2 h-8 mb-5'>
                    <Button text='5 Days' onClick={() => { setStartTime(new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000).toISOString()) }} />
                    <Button text='7 Days' onClick={() => { setStartTime(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString()) }} />
                    <Button text='14 Days' onClick={() => { setStartTime(new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000).toISOString()) }} />
                  </div>
                </>
              )
            }
            
            <DateTimeInput label={'End Time' + " (Ends " + dayjs(endTime).fromNow() + ")"} onChange={(e) => { setEndTime(e) }} value={endTime} />
            <div className='flex justify-start gap-2 h-8 mb-5'>
              <Button text='5 Days' onClick={() => { setEndTime(new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000).toISOString()) }} />
              <Button text='7 Days' onClick={() => { setEndTime(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString()) }} />
              <Button text='14 Days' onClick={() => { setEndTime(new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000).toISOString()) }} />
            </div>
            <button type="button" disabled={listingLoading || newListing.listed} className={`inline-flex items-center px-6 text-xl py-2 border border-transparent text-sm font-medium rounded-md shadow-sm ${newListing.listed && !listingLoading ? 'bg-green-100 text-green-700 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-700'}`} onClick={handleListingAction}>
              {listingLoading && (<BarLoader color="white" loading={listingLoading} size={150} />)}
              {newListing.listed && !listingLoading && (<span className="text-green-700">Active</span>)}
              {!newListing.listed && !listingLoading && <span>List</span>}
            </button>
          </div>
        )}

        {selectedMode === "Fixed Price" && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4" style={layoutStyle}>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Category</label>
              <select value={newListing.categoryId} onChange={(e) => {
                const categoryId = e.target.value;
                setNewListing((prev) => ({ ...prev, categoryId }));
              }} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
                {categorySuggestions.map((category) => (
                  <option key={category.categoryId} value={category.categoryId}>
                    {category.categoryName}
                  </option>
                ))}
              </select>
            </div>
            <div className='flex flex-col gap-2'>
              <label className="block text-sm font-medium text-gray-700">Fixed Price</label>
              <CurrencyInput
              id="fixed-price"
              name="fixedPrice"
              placeholder="Enter amount"
              defaultValue={newListing.fixedPrice / 100}
              decimalsLimit={2}
              onValueChange={(value) => {
                const cents = Math.round(parseFloat(value || 0) * 100);
                setNewListing((prev) => ({ ...prev, fixedPrice: cents }));
              }}
              prefix="$"
              className="block w-full pl-2 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            />
            </div>
            {!newListing.isFlatRate && (
              <div>
                <div className="flex items-center justify-between mb-2">
                  <label className="block text-sm font-medium text-gray-700">Dimensions & Weight</label>
                  <div className="flex items-center">
                    <input type="checkbox" className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded" checked={!newListing.isFlatRate} onChange={handleCheckboxChange} />
                    <label className="ml-2 block text-sm font-medium text-gray-700">Calculated Shipping</label>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <TextInput placeholder='L"' value={newListing.length} onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');
                    setNewListing((prev) => ({ ...prev, length: value }));
                  }} />
                  <TextInput placeholder='W"' value={newListing.width} onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');
                    setNewListing((prev) => ({ ...prev, width: value }));
                  }} />
                  <TextInput placeholder='H"' value={newListing.height} onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');
                    setNewListing((prev) => ({ ...prev, height: value }));
                  }} />
                  <TextInput placeholder="LB" value={newListing.weight} onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');
                    setNewListing((prev) => ({ ...prev, weight: value }));
                  }} />
                </div>
              </div>
            )}
            {newListing.isFlatRate && (
              <div>
                <div className="flex items-center justify-between mb-2">
                  <label className="block text-sm font-medium text-gray-700">{`Flat Rate${newListing.flatRatePrice === 0 ? ' (Free)' : ''}`}</label>
                  <div className="flex items-center">
                    <input type="checkbox" className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded" checked={!newListing.isFlatRate} onChange={handleCheckboxChange} />
                    <label className="ml-2 block text-sm font-medium text-gray-700">Calculated Shipping</label>
                  </div>
                </div>
                <CurrencyInput
                  id="flat-rate-price"
                  name="flatRatePrice"
                  placeholder="Enter amount"
                  defaultValue={newListing.flatRatePrice}
                  decimalsLimit={2}
                  onValueChange={(value) => {
                    const cents = Math.round(parseFloat(value || 0) * 100);
                    setNewListing((prev) => ({ ...prev, flatRatePrice: cents }));
                  }}
                  prefix="$"
                  className="block w-full pl-2 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                />
              </div>
            )}
            <button type="button" disabled={listingLoading || newListing.listed} className={`inline-flex items-center px-6 text-xl py-2 border border-transparent text-sm font-medium rounded-md shadow-sm ${ newListing.listed && !listingLoading ? 'bg-green-100 text-green-700 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-700'}`} onClick={handleListingAction}>
              {listingLoading && (<BarLoader color="white" loading={listingLoading} size={150} />)}
              {newListing.listed && !listingLoading && (<span className="text-green-700">Active</span>)}
              {!newListing.listed && !listingLoading && <span>List</span>}
            </button>
          </div>
        )}

        {requiredSpecifics.length > 0 && selectedMode === "Fixed Price" && (
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-6 flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-100 ring-1 ring-gray-400 rounded-lg hover:bg-gray-200 focus:outline-none">
                  <span className={`${Object.keys(newListing.specifics).length !== requiredSpecifics.length ? 'text-red-500' : ''}`}>
                    {Object.keys(newListing.specifics).length} / {requiredSpecifics.length}
                  </span>
                  <ChevronUpIcon className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`} />
                </Disclosure.Button>
                <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                  <div className="grid grid-cols-1 cq-md:grid-cols-2 cq-lg:grid-cols-4 gap-6">
                    {requiredSpecifics.map((specific, index) => (
                      <TextInput key={index} label={specific} value={newListing.specifics[specific] || ''} onChange={(e) => {
                        const value = e.target.value;
                        setNewListing((prev) => ({ ...prev, specifics: { ...prev.specifics, [specific]: value } }));
                      }} />
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        )}
      </div>

      {viewerIsOpen && (
        <ImgsViewer
          imgs={images}
          currImg={currImg}
          isOpen={viewerIsOpen}
          onClickPrev={() => setCurrImg((currImg + images.length - 1) % images.length)}
          onClickNext={() => setCurrImg((currImg + 1) % images.length)}
          onClose={() => setViewerIsOpen(false)}
          showThumbnails={true}
          backdropCloseable={true}
          spinnerDisabled={false}
          showCloseBtn={true}
          enableKeyboardInput={true}
        />
      )}
    </div>
  );
};

export default ListItem;