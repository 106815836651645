import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ListItem from '../../Components/ListItem/ListItem'; // Import the ListItem component

export default function CreateSale() {
  const location = useLocation(); // Get the current location
  const [lotIds, setLotIds] = useState([]); // Store the lotIds
  const [loading, setLoading] = useState(true); // Optional: Loading state
  const [error, setError] = useState(null); // Optional: Error state

  useEffect(() => {
    // Parse query parameters to get the 'lots' parameter
    const queryParams = new URLSearchParams(location.search);
    const lotsParam = queryParams.get('lots');

    // Check if the 'lots' parameter exists and set the lotIds directly
    if (lotsParam) {
      try {
        const parsedLotIds = JSON.parse(lotsParam);
        if (Array.isArray(parsedLotIds) && parsedLotIds.length > 0) {
          setLotIds(parsedLotIds); // Set the parsed lotIds directly
        } else if (typeof parsedLotIds === 'string' && parsedLotIds.trim() !== '') {
          setLotIds([parsedLotIds]); // Handle single lotId case
        } else {
          console.warn('Invalid format for lots parameter.');
          setLotIds([]);
        }
      } catch (err) {
        console.error('Failed to parse lots from query parameters:', err);
        setError(err); // Set error state
      } finally {
        setLoading(false); // Set loading to false after request completes
      }
    } else {
      console.warn('No lots parameter found in query.');
      setLotIds([]);
      setLoading(false); // Set loading to false
    }
  }, [location.search]); // Run the effect whenever the location's search changes

  return (
    <div className='p-4'>
      {loading && <p>Loading lot details...</p>}
      {error && <p>Error fetching lot details. Please try again later.</p>}
      {!loading && !error && (
        <div className='space-y-4'>
          <h1>Create Sale</h1>
          {lotIds.map((lotId) => (
            <ListItem typeTabs displayLot={true} key={lotId} lotId={lotId} /> // Render ListItem for each lotId
          ))}
        </div>
      )}
    </div>
  );
}
