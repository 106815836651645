'use client';

import { useState } from 'react';
import { Dialog} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';

export default function Drawer({ open, onClose, title, children, maxWidth = 'md' }) {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      {/* Background Overlay */}
      <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true" />

      {/* Drawer Container */}
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <Dialog.Panel
              transition
              className={`pointer-events-auto w-screen max-w-${maxWidth} transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700`}
            >
              <div className="flex h-full flex-col pt-20 overflow-y-scroll bg-white py-6 shadow-xl">
                {/* Header with Close Button */}
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <Dialog.Title className="text-lg font-semibold text-gray-900">{title}</Dialog.Title>
                    <button
                      type="button"
                      onClick={onClose}
                      className="ml-3 flex h-7 items-center rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                  </div>
                </div>
                {/* Drawer Content */}
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  {children}
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

// Prop types for validation and readability
Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  maxWidth: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
};

Drawer.defaultProps = {
  title: 'Drawer',
  maxWidth: 'md',
};
