import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { HashLoader } from 'react-spinners';

export default function Loading({
  loadingMessages = [
    'Loading your data...',
    'Hang tight, we are preparing things for you...',
    'Just a moment, almost there...',
  ]
}) {
  const [messageIndex, setMessageIndex] = useState(0);

  // Memoize messages to prevent unnecessary re-renders if the prop changes
  const messages = useMemo(() => loadingMessages, [loadingMessages]);

  // Callback to update message index in a stable manner
  const updateMessageIndex = useCallback(() => {
    setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
  }, [messages]);

  useEffect(() => {
    const interval = setInterval(updateMessageIndex, 3000);
    return () => clearInterval(interval); // Clean up interval on unmount
  }, [updateMessageIndex]);

  return (
    <div className="flex min-h-screen flex-1 flex-col items-center px-6 py-12 lg:px-8">
      <div className="flex flex-col items-center justify-center mt-40 gap-5">
        <HashLoader color="#5045E6" />
        <h1 className="text-xl text-center font-bold leading-6 text-gray-900 sm:text-2xl md:text-3xl">
          {messages[messageIndex]}
        </h1>
      </div>
    </div>
  );
}
