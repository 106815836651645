import React, { useState, useEffect } from 'react';
import { BarLoader } from 'react-spinners';
import axiosInstance from '../../axiosInstance';
import { useNavigate } from 'react-router-dom';
import OfferModal from '../OfferModal/OfferModal';

function TaskList() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [taskCounts, setTaskCounts] = useState({});

    const [showOfferModal, setShowOfferModal] = useState(false);

    useEffect(() => {
        axiosInstance.get('/v1/crew/member/tasks').then((response) => {
            setTaskCounts(response.data);
            setLoading(false);
        }).catch((error) => {
            setError(error.response?.data?.error || 'Failed to fetch data');
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <div className='bg-white shadow overflow-hidden rounded-lg flex items-center justify-center'>
                <BarLoader color='#2563EB' width={"100%"} />
            </div>
        );
    }

    if (error) {
        return (
            <div className='bg-white shadow overflow-hidden rounded-lg flex items-center justify-center'>
                <p className='text-red-500'>{error}</p>
            </div>
        );
    }
        
    
    return (
        <div>
            <OfferModal isOpen={showOfferModal} onClose={setShowOfferModal} />
            <h3 className='text-lg font-semibold'>Tasks</h3>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
                {/* Orders, Offers */}
                <div className='flex justify-between items-center ring-1 ring-gray-200 rounded-lg p-4' onClick={() => navigate('/Dashboard/Orders?sort=%7B"createdAt"%3A-1%7D&status=Paid&page=1&pageSize=25')}>
                    <h4 className='text-lg font-semibold'>Orders - Ship Now</h4>
                    <p className='text-lg bg-red-500 px-2 rounded-full text-white'>{taskCounts.onlineOrdersPaid}</p>
                </div>
                <div className='flex justify-between items-center ring-1 ring-gray-200 rounded-lg p-4' onClick={() => navigate('/Dashboard/Orders?sort=%7B%22createdAt%22%3A-1%7D&status=Awaiting+Payment&page=1&pageSize=25')}>
                    <h4 className='text-lg font-semibold'>Orders - Awaiting Pickup/Payment</h4>
                    <p className='text-lg bg-red-500 px-2 rounded-full text-white'>{taskCounts.offlineOrdersWaiting}</p>
                </div>
                <div className='flex justify-between items-center ring-1 ring-gray-200 rounded-lg p-4 cursor-pointer' onClick={() => {setShowOfferModal(true)}}>
                    <h4 className='text-lg font-semibold'>Offers - Respond Now</h4>
                    <p className='text-lg bg-red-500 px-2 rounded-full text-white'>{taskCounts.offersPending}</p>
                </div>
                
                
            </div>
        </div>
    );
}

export default TaskList;
