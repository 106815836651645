import React, { useMemo } from 'react';
import * as Spinners from 'react-spinners';
import { PencilIcon } from 'lucide-react';

const Button = ({
  size = 'md',
  iconPosition = 'left',
  Icon,
  children,
  text = '',
  className = '',
  spinner = null,
  ...rest
}) => {
  const sizes = {
    xs: 'px-2 py-1 text-xs',
    sm: 'px-2.5 py-1.5 text-sm',
    md: 'px-3 py-2',
  };

  const iconPositions = {
    left: '-ml-0.5',
    right: '-mr-0.5',
  };

  // Memoize the Spinner component to prevent unnecessary re-renders
  const SpinnerComponent = useMemo(() => (spinner ? Spinners[spinner] : null), [spinner]);

  return (
    <button
      type="button"
      className={`inline-flex items-center justify-center gap-x-1.5 rounded-md bg-indigo-600 text-white font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${sizes[size]} ${className}`}
      {...rest}
    >
      {/* Render icon on the left if provided and spinner is not shown */}
      {Icon && iconPosition === 'left' && !SpinnerComponent && (
        <Icon className={`${iconPositions[iconPosition]} h-5 w-5`} aria-hidden="true" />
      )}

      {/* Conditionally render the spinner or the button text */}
      {SpinnerComponent ? (
        <SpinnerComponent color="white" size={20} />
      ) : (
        <span>{text || children}</span>
      )}

      {/* Render icon on the right if provided and spinner is not shown */}
      {Icon && iconPosition === 'right' && !SpinnerComponent && (
        <Icon className={`${iconPositions[iconPosition]} h-5 w-5`} aria-hidden="true" />
      )}
    </button>
  );
};

export default Button;
