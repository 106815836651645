import React, { useState } from 'react';
import OverviewStatistic from '../../Components/Statistic/OverviewStatistic.js';
import ListingStatistic from '../../Components/Statistic/ListingStatistic.js';
import TodoStatistic from '../../Components/Statistic/TodoStatistic.js';
import { Tab, Menu } from '@headlessui/react';
import { ChevronDown, RotateCw } from 'lucide-react';

function Dashboard({}) {
    const [timeFrame, setTimeFrame] = useState('today');
    const [reloadKey, setReloadKey] = useState(0); // Used to force reload components

    const handleReload = () => {
        setReloadKey((prevKey) => prevKey + 1); // Increment key to trigger remount
    };

    return (
        <div className='flex flex-col gap-4 p-4'>
            <div className='flex justify-between'>
                <div className='flex gap-2 items-center'>
                    <h1 className='text-2xl font-semibold'>Dashboard</h1>
                    <button
                        className='bg-none border-none outline-none'
                        onClick={handleReload}
                        title="Reload Statistics"
                    >
                        <RotateCw size={16} />
                    </button>
                </div>

                <Menu as="div" className="relative inline-block sm:hidden text-left">
                    <div>
                        <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
                            {timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)}
                            <ChevronDown className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
                        </Menu.Button>
                    </div>
                    <Menu.Items className="absolute z-20 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        onClick={() => setTimeFrame('today')}
                                    >
                                        Today
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        onClick={() => setTimeFrame('7-day')}
                                    >
                                        7-Days
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        onClick={() => setTimeFrame('30-day')}
                                    >
                                        30-Days
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        onClick={() => setTimeFrame('90-day')}
                                    >
                                        90-Days
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Menu>

                
                <Tab.Group className="sm:block hidden" onChange={(index) => setTimeFrame(['today', '7-day', '30-day', '90-day'][index])}>
                    <Tab.List className='flex gap-4'>
                        <Tab
                            className={({ selected }) =>
                                `outline-none ${
                                    selected ? 'bg-indigo-600 text-white' : 'text-indigo-600'
                                } px-4 py-2 font-semibold rounded-lg`
                            }
                        >
                            Today
                        </Tab>
                        <Tab
                            className={({ selected }) =>
                                `outline-none ${
                                    selected ? 'bg-indigo-600 text-white' : 'text-indigo-600'
                                } px-4 py-2 font-semibold rounded-lg`
                            }
                        >
                            7-Days
                        </Tab>
                        <Tab
                            className={({ selected }) =>
                                `outline-none ${
                                    selected ? 'bg-indigo-600 text-white' : 'text-indigo-600'
                                } px-4 py-2 font-semibold rounded-lg`
                            }
                        >
                            30-Days
                        </Tab>
                        <Tab
                            className={({ selected }) =>
                                `outline-none ${
                                    selected ? 'bg-indigo-600 text-white' : 'text-indigo-600'
                                } px-4 py-2 font-semibold rounded-lg`
                            }
                        >
                            90-Days
                        </Tab>
                    </Tab.List>
                </Tab.Group>
            </div>
            <OverviewStatistic key={`overview-${reloadKey}`} label={"Sales"} className='flex-grow' timeFrame={timeFrame} />
            <TodoStatistic key={`todo-${reloadKey}`} />
            <ListingStatistic key={`listing-${reloadKey}`} timeFrame={timeFrame} className='flex-grow' />
        </div>
    );
}

export default Dashboard;
