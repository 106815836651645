
import React, { Fragment } from 'react';

import Chip from '../Chip/Chip';
import Button from '../Button/Button';


import ebayIcon from '../../Assets/Images/ebay_icon.svg';
import facebookIcon from '../../Assets/Images/facebook_icon.svg';
import memberlogo from '../../Assets/Images/memberlogo.webp';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';




const ListingCell = (params) => {
    const listings = params.value;
    const setLotToList = params.setLotToList;
    
    if (!listings || listings.length === 0 ) {
        return (
            <div className="flex flex-col justify-center items-start h-full">
                <p className="text-sm text-gray-500">No Listings</p>
                {/* listing call to action */}
                <Button
                    onClick={() => setLotToList(params.lotId)}
                    className='h-8 px-4 mt-2'
                >
                    List Now
                </Button>
            </div>
        )
    }
    return (
      <div className="flex flex-col justify-center items-start h-full">
        <div className="flex justify-evenly gap-2">
          {listings.map((listing, index) => (
            <Fragment key={index}>
              <div
                className="flex flex-col items-center justify-evenly h-full leading-tight min-w-[80px] p-1 border rounded"
                title={`Status: ${listing.status}`}
              >
                
                {/* Auction Listing Rendering with dayjs */}
                {listing.type === 'Auction' ? (
                  <>
                    <p className="text-sm">Bids: {listing.bidCount}</p>
                    <p className="text-sm">
                      {dayjs(listing.endTime).isAfter(dayjs())
                        ? `Ends ${dayjs(listing.endTime).fromNow()}`
                        : `Ended ${dayjs(listing.endTime).fromNow()}`}
                    </p>
                  </>
                ) : (
                  <>
                    {listing.platform === 'Ebay' && listing.ebayItemId && (
                      <a
                        href={`https://ebay.com/itm/${listing.ebayItemId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={ebayIcon} className="h-5 mt-0" alt="Ebay Icon" />
                      </a>
                    )}
                    {listing.platform === 'Facebook' && listing.facebookItemId && (
                      <a
                        href={`https://www.facebook.com/marketplace/item/${listing.facebookItemId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebookIcon} className="h-4" alt="Facebook Icon" />
                      </a>
                    )}
                    {listing.platform === 'Member' && listing.lotId && (
                      <a
                        href={`https://5dollarauctions.com/lot/${listing.lotId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={memberlogo} className="h-5" alt="Member Icon" />
                      </a>
                    )}
                  </>
                )}
                <p
                  className={`font-semibold text-lg ${
                    listing.status === 'Sold'
                      ? 'text-green-500'
                      : listing.status === 'Unsold'
                      ? 'line-through text-red-400'
                      : ''
                  }`}
                  title={listing.status}
                >
                  ${(listing.currentPrice / 100).toFixed(2)}
                </p>
                <div className="mt-1">
                  <p className="text-sm">{listing.status}</p>
                </div>
              </div>
              {index < listings.length - 1 && <div className="h-full w-0.5 bg-gray-300" />}
            </Fragment>
          ))}
        </div>
      </div>
    );
  };

export default ListingCell;