import React from "react";
import dayjs from "dayjs";

const DateTimeInput = ({
  label,
  value,
  onChange,
  placeholder = "Select date and time",
  error,
  helpText,
  warning,
  hints,
}) => {
  const inputId = label ? label.toLowerCase().replace(/ /g, "-") : "datetime-input";

  // Convert value to local datetime format for the input field
  const formatToInputValue = (val) => {
    if (!val) return ""; // Empty fallback
    const localDate = dayjs(val); // Convert to dayjs object
    return localDate.isValid() ? localDate.format("YYYY-MM-DDTHH:mm") : ""; // Return formatted date
  };

  // Handle change and convert input value back to ISO format
  const handleChange = (e) => {
    const newValue = e.target.value;
    if (onChange) {
      const localDate = dayjs(newValue); // Parse the input value
      if (localDate.isValid()) {
        onChange(localDate.toISOString()); // Convert back to ISO format
      }
    }
  };

  return (
    <div className="my-4">
      {label && (
        <div className="flex justify-between">
          <label
            htmlFor={inputId}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {label}
          </label>
          {hints && <span className="text-sm leading-6 text-gray-500">{hints}</span>}
        </div>
      )}

      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type="datetime-local"
          id={inputId}
          value={formatToInputValue(value)} // Correctly format and display the value
          onChange={handleChange} // Handle changes properly
          placeholder={placeholder}
          className={`block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 
            ${error ? "ring-red-500" : "ring-gray-300"} 
            ${warning ? "ring-yellow-500" : ""}
          `}
        />
      </div>

      {helpText && <p className="mt-2 text-sm text-gray-500">{helpText}</p>}
      {warning && <p className="mt-2 text-sm text-yellow-500">{warning}</p>}
      {error && (
        <p className="text-sm text-red-600 mt-2" id={`${inputId}-error`}>
          {error}
        </p>
      )}
    </div>
  );
};

export default DateTimeInput;
