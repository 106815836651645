import React, { useEffect, useState, useMemo, useCallback, useRef, Fragment } from 'react';
import axiosInstance from '../../axiosInstance';
import Chip from '../../Components/Chip/Chip';
import Drawer from '../../Components/Drawer/Drawer';
import ListItem from '../../Components/ListItem/ListItem';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import Loading from '../Loading/Loading';
import dayjs from 'dayjs';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { Search, X } from 'lucide-react';
import Button from '../../Components/Button/Button';
import DropdownButton from '../../Components/DropdownButton/DropdownButton';
import Tags from '../../Components/TableCells/Tags';
import ConditionCell from '../../Components/TableCells/ConditionCell';
import ListingCell from '../../Components/TableCells/ListingCell';



// Headless UI
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

// Utility to combine class names
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Define your lot status filter options
const lotStatusOptions = [
  { label: 'All Statuses', value: '' },
  { label: 'Idle', value: 'Idle' },
  { label: 'Active', value: 'Active' },
  { label: 'Sold', value: 'Sold' },
  { label: 'Completed', value: 'Completed' },
];

export default function ViewLots() {
  // Initialize searchParams
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize state variables from URL query parameters
  const [sort, setSort] = useState(() => {
    const sortParam = searchParams.get('sort');
    if (sortParam) {
      const [field, order] = sortParam.split(':');
      return { [field]: order === 'desc' || order === '-1' ? -1 : 1 };
    }
    return { createdAt: -1 }; // Default sort
  });

  const [filters, setFilters] = useState(() => {
    const statusParam = searchParams.get('status') || '';
    return {
      status: statusParam,
    };
  });

  const [page, setPage] = useState(() => {
    const pageParam = parseInt(searchParams.get('page'), 10);
    return !isNaN(pageParam) && pageParam > 0 ? pageParam - 1 : 0;
  });

  const [pageSize, setPageSize] = useState(() => {
    const pageSizeParam = parseInt(searchParams.get('pageSize'), 10);
    return !isNaN(pageSizeParam) ? pageSizeParam : 25;
  });

  const [searchText, setSearchText] = useState(() => searchParams.get('searchText') || '');

  const [rowData, setRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [lotToList, setLotToList] = useState(null);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  const navigate = useNavigate();

  // Ref to store the current width of the thumbnail column
  const thumbnailColumnWidthRef = useRef(120); // Default width

  // Synchronize state variables with URL query parameters
  useEffect(() => {
    const params = new URLSearchParams();

    // Set sort
    const sortField = Object.keys(sort)[0];
    const sortOrder = sort[sortField] === -1 ? 'desc' : 'asc';
    params.set('sort', `${sortField}:${sortOrder}`);

    // Set filters
    if (filters.status) {
      params.set('status', filters.status);
    } else {
      params.delete('status');
    }

    // Set page (1-based for URL)
    params.set('page', (page + 1).toString());

    // Set pageSize
    params.set('pageSize', pageSize.toString());

    // Set searchText
    if (searchText) {
      params.set('searchText', searchText);
    } else {
      params.delete('searchText');
    }

    setSearchParams(params, { replace: true });
  }, [sort, filters, page, pageSize, searchText, setSearchParams]);

  const renderThumbnail = (value, title) => (
    <div className="flex items-center h-full justify-center">
      <div
        className="flex items-center justify-center bg-gray-100 border border-gray-300 rounded overflow-hidden"
        style={{ width: '100%', aspectRatio: '1/1' }} // Fill the cell's width and height
      >
        <img
          src={
            value
              ? value.includes('gavelbase.s3')
                ? value.replace('images/', 'thumbnails/')
                : value
              : value
          }
          alt={`Thumbnail for ${title}`}
          className="object-contain w-full h-full"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = 'https://via.placeholder.com/96';
          }}
        />
      </div>
    </div>
  );

  const renderLotTitle = (value, lotNumber) => (
    <div
      style={{ overflowX: 'hidden', whiteSpace: 'normal', wordWrap: 'break-word' }}
      className="h-full flex justify-center flex-col"
    >
      <p className="leading-tight font-bold">{lotNumber}</p>
      <p className="leading-tight">{value}</p>
    </div>
  );

  const renderStatus = (status) => {
      switch (status) {
        case 'Idle':
          return (
            <div className="flex items-center h-full">
              <Chip text={status} />
            </div>
          );
        case 'Active':
          return (
            <div className="flex items-center h-full">
              <Chip text={status} color="green" />
            </div>
          );
        case 'Sold':
          return (
            <div className="flex items-center h-full">
              <Chip text={status} color="red" />
            </div>
          );
        case 'Completed':
          return (
            <div className="flex items-center h-full">
              <Chip text={status} color="blue" />
            </div>
          );
        default:
          return status;
      }
    };

  // status and location in this cell
  const renderLocation = (params) => {
    return (
      <div className="flex flex-col items-center h-full">
        <p className="font-bold">{params.value}</p>
        {renderStatus(params.data.status)}
      </div>
    );
  };

  // *******************************
  // MERGED CELL RENDERER (for listings)
  // *******************************

  // Column definitions for AG Grid
  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Thumbnail',
        field: 'thumbnail',
        width: 120,
        resizable: true,
        sortable: false,
        cellRenderer: (params) => renderThumbnail(params.value, params.data.title),
      },
      {
        headerName: 'Lot Number/Title',
        field: 'title',
        flex: 2,
        sortable: true,
        sort: sort['title'] ? (sort['title'] === -1 ? 'desc' : 'asc') : undefined,
        cellRenderer: (params) => renderLotTitle(params.value, params.data.lotNumber),
      },
      {
        headerName: 'Condition',
        field: 'condition',
        flex: 1,
        sortable: true,
        sort: sort['condition'] ? (sort['condition'] === -1 ? 'desc' : 'asc') : undefined,
        cellRenderer: (params) => <ConditionCell conditionObject={params.value} />,
      },
      // {
      //   headerName: 'Status',
      //   field: 'status',
      //   flex: 1,
      //   sortable: true,
      //   sort: sort['status'] ? (sort['status'] === -1 ? 'desc' : 'asc') : undefined,
      //   cellRenderer: (params) => renderStatus(params.value),
      // },
      {
        headerName: 'Platforms',
        field: 'listings',
        flex: 2,
        cellRenderer: (params) => <ListingCell value={params.value} setLotToList={setLotToList} lotId={params.data.id} />,
      },
      {
        headerName: 'Location',
        field: 'location',
        flex: 1,
        sortable: true,
        sort: sort['location'] ? (sort['location'] === -1 ? 'desc' : 'asc') : undefined,
        // location and status in this cell
        cellRenderer: (params) => renderLocation(params),
      },
      {
        headerName: 'Tags',
        field: 'tags',
        flex: 1,
        sortable: false,
        cellRenderer: (params) => <Tags tags={params.value} />,
      },
      {
        headerName: 'Actions',
        field: 'actions',
        width: 120,
        sortable: false,
        cellStyle: { overflow: 'visible' },
        cellRenderer: (params) => (
          <div className="flex items-center justify-end h-full">
            <DropdownButton
              text="Edit / View"
              size="sm"
              onClick={() => navigate(`./Edit/${params.data.id}`)}
              className="flex items-center justify-center gap-x-1.5 bg-indigo-600 text-white font-semibold shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md"
              dropdownOptions={[
                { label: 'List Now', onClick: () => setLotToList(params.data.id) },
              ]}
            />
          </div>
        ),
      },
    ],
    [navigate, sort]
  );

  // Define a custom row height to match the thumbnail column's width
  const getRowHeight = () => {
    return thumbnailColumnWidthRef.current;
  };

  // Fetch data from the server
  const fetchData = useCallback(async () => {
    setLoading(true);
    const offset = page * pageSize;
    const count = pageSize;

    try {
      const response = await axiosInstance.post('/v1/crew/lot/keyword-search', {
        keyword: searchText,
        offset,
        count,
        sort,
        filters, // Include filters with status
      });

      if (response.status === 200) {
        // Combine results + similarResults
        const combinedLots = [...response.data.results, ...response.data.similarResults];
        const formattedData = combinedLots.map((lot) => ({
          id: lot._id,
          ...lot,
          brand: lot.details?.brand || 'N/A',
          model: lot.details?.model || 'N/A',
          location: lot.location?.label || 'Not Located',
          createdAt: lot.createdAt,
          condition: lot.condition || null,
        }));
        if (isMounted.current) {
          setRowData(formattedData);
          setTotalCount(response.data.totalSearchCount);
        }
      } else {
        if (isMounted.current) {
          setRowData([]);
        }
      }
    } catch (error) {
      if (isMounted.current) {
        setRowData([]);
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  }, [page, pageSize, searchText, sort, filters]);

  // Fetch data when dependencies change
  useEffect(() => {
    isMounted.current = true;
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [fetchData]);

  // AG Grid configuration
  const gridOptions = useMemo(
    () => ({
      columnDefs,
      rowData,
      pagination: false,
      domLayout: 'autoHeight',
      getRowHeight,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading...</span>',
      overlayNoRowsTemplate: loading
        ? '<span class="ag-overlay-loading-center">Loading...</span>'
        : '<span class="ag-overlay-no-rows-center">Sorry, no matching records found</span>',
      onGridReady: (params) => {
        // Size columns to fit the grid
        params.api.sizeColumnsToFit();
      },
      onColumnResized: (params) => {
        if (params.column && params.column.getColId() === 'thumbnail') {
          thumbnailColumnWidthRef.current = params.column.getActualWidth();
          params.api.resetRowHeights(); // Recalculate row heights
        }
      },
      onSortChanged: (params) => {
        const sortModel = params.api.getSortModel();
        if (sortModel.length > 0) {
          const { colId, sort: sortOrder } = sortModel[0];
          setSort({ [colId]: sortOrder === 'desc' ? -1 : 1 });
        } else {
          setSort({ createdAt: -1 });
        }
        setPage(0);
      },
    }),
    [columnDefs, rowData, loading]
  );

  // Handle page changes
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Handle page size changes
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  // Handle search
  const handleSearch = (text) => {
    setSearchText(text);
    setPage(0);
  };

  // Handle clear search
  const handleClearSearch = () => {
    setSearchText('');
    setPage(0);
  };

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / pageSize);

  // Custom Search Component
  const CustomSearchRender = ({ searchText, onSearch, onClear }) => {
    const [text, setText] = useState(searchText || '');

    useEffect(() => {
      setText(searchText || '');
    }, [searchText]);

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onSearch(text);
      }
    };

    const handleSearchClick = () => {
      onSearch(text);
    };

    const handleClear = () => {
      setText('');
      onClear();
    };

    return (
      <div className="flex items-center space-x-2">
        <div className="relative">
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Search"
            className="block w-full rounded-md border-gray-300 pl-10 pr-3 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
        </div>
        <Button onClick={handleSearchClick} variant="primary">
          Search
        </Button>
        <Button onClick={handleClear} variant="secondary">
          <X className="h-5 w-5" />
        </Button>
      </div>
    );
  };

  // *******************************
  // RENDER LISTBOX FOR LOT STATUS
  // *******************************
  const selectedStatusObj =
    lotStatusOptions.find((opt) => opt.value === filters.status) || lotStatusOptions[0];

  function renderStatusListbox() {
    return (
      <Listbox
        value={selectedStatusObj}
        onChange={(selectedObj) => {
          setFilters((prev) => ({ ...prev, status: selectedObj.value }));
          setPage(0);
        }}
      >
        {({ open }) => (
          <div className="relative">
            <Listbox.Button
              className="relative w-44 cursor-default rounded-md 
              border border-gray-300 bg-white py-2 pl-3 pr-10 text-left 
              shadow-sm focus:outline-none sm:text-sm"
            >
              <span className="block truncate">{selectedStatusObj.label}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              as={Fragment}
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute z-10 mt-1 max-h-60 w-44 overflow-auto rounded-md 
                  bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 
                  focus:outline-none sm:text-sm"
              >
                {lotStatusOptions.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {option.label}
                        </span>
                        {selected ? (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-indigo-600' : 'text-indigo-600'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    );
  }

  return (
    <div className="flex flex-col h-full bg-gray-50 min-h-screen">
      <Drawer
        onClose={() => setLotToList(null)}
        title="List Now"
        open={lotToList !== null}
      >
        <ListItem typeTabs displayLot={true} lotId={lotToList} />
      </Drawer>
      {/* Header Section */}
      <div className="bg-white shadow border-b p-6">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex flex-col">
            <h2 className="text-2xl font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Lots
            </h2>
            <p className="text-xs text-gray-600">Search Results ({totalCount})</p>
          </div>
          <div className="mt-5 flex flex-wrap lg:ml-4 lg:mt-0 space-x-4 items-center">
            {renderStatusListbox()}
            <CustomSearchRender
              searchText={searchText}
              onSearch={handleSearch}
              onClear={handleClearSearch}
            />
            <Link
              to="./Create"
              className="inline-flex items-center px-4 py-2 text-sm font-semibold text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-md"
            >
              Create Lot
            </Link>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col flex-1">
        <div
          className="ag-theme-material w-full"
          style={{ flex: '1 1 auto', overflow: 'hidden' }}
        >
          <AgGridReact {...gridOptions} />
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center p-3">
          <div>
            <span className="text-sm text-gray-700">
              Page {page + 1} of {totalPages} ({totalCount} lots)
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(Math.max(page - 1, 0))}
              disabled={page === 0}
              className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                page === 0
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-500'
              } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(Math.min(page + 1, totalPages - 1))}
              disabled={page + 1 >= totalPages}
              className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                page + 1 >= totalPages
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-500'
              } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Next
            </button>
            <select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              className="rounded-md border border-gray-300 bg-white py-2 px-3 pr-8 text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              {[10, 20, 30, 40, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size} / page
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Loading Overlay */}
        {loading && <Loading />}
      </div>
    </div>
  );
}
