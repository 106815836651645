// axiosInstance.js
import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; // Corrected import

const createServerUrl = (path) => {
    return `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN}${process.env.REACT_APP_SERVER_PORT ? ':'+process.env.REACT_APP_SERVER_PORT : ''}${path}`;
};

// Helper to get cookies
const getCookie = (name) => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=');
        if (cookieName === name) return cookieValue;
    }
    return null;
};

// Helper function to decode JWT and check expiry
function isTokenExpired(token) {
    const decoded = jwtDecode(token);
    return decoded.exp * 1000 < Date.now();
}

// Function to refresh the access token using the refresh token stored in cookies
async function refreshToken() {
    try {
        const response = await axios.post(
            createServerUrl('/v1/public/crew/refresh'),
            {
                refreshToken: getCookie('refreshToken'),
            },
            { withCredentials: true }
        );
        // Store new access token
        document.cookie = `accessToken=${response.data.accessToken}; path=/;`;
        return response.data.accessToken;
    } catch (error) {
        console.error('Failed to refresh token', error);
        throw error;
    }
}

// Create Axios instance
const axiosInstance = axios.create({
    baseURL: createServerUrl('/'),
    withCredentials: true,
});

// Request interceptor to check token expiry before making a request
axiosInstance.interceptors.request.use(
    async (config) => {
        let token = getCookie('accessToken');

        if (token) {
            if (isTokenExpired(token)) {
                // Refresh token if expired
                try {
                    token = await refreshToken();
                } catch (error) {
                    console.error('Error refreshing token in request interceptor', error);
                    return Promise.reject(error);
                }
            }
            // Attach new or existing token to request headers
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor to handle 401 errors and retry with refreshed token if needed
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const newToken = await refreshToken();
                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                console.error('Failed to refresh token after 401', refreshError);
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
