import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import axiosInstance from '../../axiosInstance';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { Search, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import Button from '../../Components/Button/Button';
import Loading from '../Loading/Loading';

import Tags from '../../Components/TableCells/Tags';
import ConditionCell from '../../Components/TableCells/ConditionCell';

export default function CreateListings() {
  const [rowData, setRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  // Table state
  const [page, setPage] = useState(0); // Zero-based index
  const [pageSize, setPageSize] = useState(25);
  const [searchText, setSearchText] = useState('');

  // Selected lot IDs state
  const [selectedLots, setSelectedLots] = useState([]);

  // Ref to track if the component is mounted
  const isMounted = useRef(true);

  // Ref to store the current width of the thumbnail column
  const thumbnailColumnWidthRef = useRef(120); // Default width

  // AG Grid API references
  const gridApiRef = useRef(null);

  // Flag to prevent onSelectionChanged during data loading or synchronization
  const suppressSelectionChangedRef = useRef(false);

  // Column definitions for AG Grid
  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'checkbox',
        checkboxSelection: true,
        width: 50,
        suppressMenu: true,
        sortable: false,
        filter: false,
        resizable: false,
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      },
      {
        headerName: 'Thumbnail',
        field: 'thumbnail',
        width: 120,
        resizable: true,
        cellRenderer: (params) => {
          const title = params.data.title;
          const value = params.value;
          return (
            <div className="flex items-center h-full justify-center">
              <div
                className="flex items-center justify-center bg-gray-100 border border-gray-300 rounded overflow-hidden"
                style={{ width: '100%', aspectRatio: '1/1' }} // Fill the cell's width and height
              >
                <img
                  src={
                    value
                      ? value.includes('gavelbase.s3')
                        ? value.replace('/images/', '/thumbnails/')
                        : value
                      : 'https://via.placeholder.com/96'
                  }
                  alt={`Thumbnail for ${title}`}
                  className="object-cover w-full h-full"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'https://via.placeholder.com/96';
                  }}
                />
              </div>
            </div>
          );
        },
      },
      {
        headerName: 'Lot Number/Title',
        field: 'title',
        flex: 1,
        cellRenderer: (params) => {
          const lotNumber = params.data.lotNumber;
          const value = params.value;
          return (
            <div
              style={{ overflowX: 'hidden', whiteSpace: 'normal', wordWrap: 'break-word' }}
              className="h-full flex justify-center flex-col"
            >
              <p className="leading-tight font-bold">{lotNumber}</p>
              <p className="leading-tight">{value}</p>
            </div>
          );
        },
      },
      {
        headerName: 'Condition',
        field: 'condition',
        flex: 1,
        cellRenderer: (params) => <ConditionCell conditionObject={params.value} />,
      },
      {
        headerName: 'Tags',
        field: 'tags',
        flex: 1,
        cellRenderer: (params) => {
          const tags = params.data.tags;
          return <Tags tags={tags} editable={false} />;
        },
      },
      {
        headerName: 'Status',
        field: 'status',
        flex: 1,
      },
    ],
    []
  );

  // Define a custom row height to match the thumbnail column's width
  const getRowHeight = useCallback(() => {
    return thumbnailColumnWidthRef.current + 25; // Adding some padding
  }, []);

  // Custom Search Component (remains the same)
  const CustomSearchRender = ({ searchText, onSearch, onClear }) => {
    const [text, setText] = useState(searchText || '');

    useEffect(() => {
      setText(searchText || '');
    }, [searchText]);

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onSearch(text);
      }
    };

    const handleSearchClick = () => {
      onSearch(text);
    };

    const handleClear = () => {
      setText('');
      onClear();
    };

    return (
      <div className="flex items-center space-x-2">
        <div className="relative">
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Search"
            className="block w-full rounded-md border-gray-300 pl-10 pr-3 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
        </div>
        <Button onClick={handleSearchClick} variant="primary">
          Search
        </Button>
        <Button onClick={handleClear} variant="secondary">
          <X className="h-5 w-5" />
        </Button>
      </div>
    );
  };

  // Fetch data from the server
  const fetchData = useCallback(async () => {
    setLoading(true);

    const offset = page * pageSize;
    const count = pageSize;

    try {
      const response = await axiosInstance.post('/v1/crew/lot/keyword-search', {
        keyword: searchText,
        offset: offset,
        count: count,
        sort: { createdAt: -1 },
        filters: { status: 'Idle' },
      });

      if (response.status === 200) {
        const combinedLots = [...response.data.results, ...response.data.similarResults];
        const formattedData = combinedLots.map((lot) => ({
          id: lot._id,
          ...lot,
          brand: lot.details?.brand || 'N/A',
          model: lot.details?.model || 'N/A',
          location: lot.location?.label || 'Not Located',
          createdAt: lot.createdAt,
          condition: lot.condition || null,
        }));
        if (isMounted.current) {
          suppressSelectionChangedRef.current = true; // Suppress selection changes during data load
          setRowData(formattedData);
          setTotalCount(response.data.totalSearchCount);
        }
      } else {
        console.warn('Unexpected response status:', response.status);
        if (isMounted.current) {
          setRowData([]);
        }
      }
    } catch (error) {
      console.error('Error fetching lots:', error);
      if (isMounted.current) {
        setRowData([]);
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
        suppressSelectionChangedRef.current = false; // Re-enable selection changes
      }
    }
  }, [page, pageSize, searchText]);

  // Fetch data when page, pageSize, or searchText change
  useEffect(() => {
    isMounted.current = true; // Set the mounted flag to true
    fetchData();
    return () => {
      isMounted.current = false; // Cleanup function to set the mounted flag to false
    };
  }, [fetchData]);

  // Use getRowNodeId to ensure AG Grid can track rows by unique IDs
  const getRowNodeId = useCallback((data) => data.id, []);

  // AG Grid configuration
  const gridOptions = useMemo(
    () => ({
      columnDefs: columnDefs,
      rowData: rowData,
      pagination: false, // We'll handle pagination manually
      domLayout: 'autoHeight',
      getRowHeight: getRowHeight,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      deltaRowDataMode: true,
      getRowNodeId: getRowNodeId,
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center">Loading...</span>',
      overlayNoRowsTemplate:
        loading
          ? '<span class="ag-overlay-loading-center">Loading...</span>'
          : '<span class="ag-overlay-no-rows-center">Sorry, no matching records found</span>',
      onGridReady: (params) => {
        gridApiRef.current = params.api;
        params.api.sizeColumnsToFit();
      },
      onColumnResized: (params) => {
        if (params.column && params.column.getColId() === 'thumbnail') {
          thumbnailColumnWidthRef.current = params.column.getActualWidth();
          params.api.resetRowHeights();
        }
      },
      onSelectionChanged: () => {
        if (suppressSelectionChangedRef.current) {
          return;
        }
        const selectedNodes = gridApiRef.current.getSelectedNodes();
        const selectedIds = selectedNodes.map((node) => node.data.id);
        setSelectedLots((prevSelected) => {
          // Combine previous selections with current selections
          const newSelected = new Set(prevSelected);
          selectedIds.forEach((id) => newSelected.add(id));
          // Remove IDs that are no longer selected in the grid
          prevSelected.forEach((id) => {
            if (!selectedIds.includes(id) && rowData.find((row) => row.id === id)) {
              newSelected.delete(id);
            }
          });
          return Array.from(newSelected);
        });
      },
    }),
    [columnDefs, rowData, getRowHeight, loading, getRowNodeId]
  );

  // Handle selection synchronization when data changes
  useEffect(() => {
    if (gridApiRef.current) {
      suppressSelectionChangedRef.current = true;
      gridApiRef.current.forEachNode((node) => {
        const isSelected = selectedLots.includes(node.data.id);
        node.setSelected(isSelected);
      });
      suppressSelectionChangedRef.current = false;
    }
  }, [rowData, selectedLots]);

  // Handle page changes
  const handlePageChange = (newPage) => {
    setPage(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Handle page size changes
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0); // Reset to first page
  };

  // Handle search
  const handleSearch = (text) => {
    setSearchText(text);
    setPage(0); // Reset to first page
  };

  // Handle clear search
  const handleClearSearch = () => {
    setSearchText('');
    setPage(0);
  };

  // Create query parameters from selectedLots array
  const queryString = selectedLots.length
    ? `?lots=${encodeURIComponent(JSON.stringify(selectedLots))}`
    : '';

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <div className="flex flex-col h-full bg-gray-50 min-h-screen">
      {/* Header Section */}
      <div className="bg-white shadow border-b p-6">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Create Listings
            </h2>
          </div>
          <div className="mt-5 flex lg:ml-4 lg:mt-0 space-x-4">
            <CustomSearchRender
              searchText={searchText}
              onSearch={handleSearch}
              onClear={handleClearSearch}
            />
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col flex-1 relative">
        <div
          className="ag-theme-material w-full"
          style={{ flex: '1 1 auto', overflow: 'hidden' }}
        >
          <AgGridReact {...gridOptions} />
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center p-3">
          <div>
            <span className="text-sm text-gray-700">
              Page {page + 1} of {totalPages}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(Math.max(page - 1, 0))}
              disabled={page === 0}
              className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                page === 0
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-500'
              } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(Math.min(page + 1, totalPages - 1))}
              disabled={page + 1 >= totalPages}
              className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                page + 1 >= totalPages
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-500'
              } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Next
            </button>
            <select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              className="rounded-md border border-gray-300 bg-white py-2 px-3 pr-8 text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            >
              {[10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size} / page
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Buttons to navigate to Auction or Sale with selected lots */}
        <div className="flex space-x-4 p-3">
          <Button as={Link} to={`./Auction${queryString}`} variant="primary">
            For Auction
          </Button>

          <Link to={`./Sale${queryString}`}>
            <Button variant="secondary">For Sale</Button>
          </Link>
        </div>

        {/* Display selected lot IDs */}
        <div style={{ marginTop: '20px' }}>
          <h3>Selected Lots IDs:</h3>
          <p>{selectedLots.length}</p>
          <pre>{JSON.stringify(selectedLots, null, 2)}</pre>
        </div>

        {/* Loading Overlay */}
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-opacity-50 bg-gray-100">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}
