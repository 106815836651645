import React, { useState, useEffect } from 'react';
import ImgsViewer from 'react-images-viewer';
import TextInput from '../../Components/TextInput/TextInput';
import { toast } from 'react-toastify';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { BarLoader } from 'react-spinners';

const ListItemModal = ({ lotData, visible, onClose }) => {
  const [listingLoading, setListingLoading] = useState(false);
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [requiredSpecifics, setRequiredSpecifics] = useState([]);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currImg, setCurrImg] = useState(0);
  const [newListing, setNewListing] = useState({
    fixedPrice: '',
    length: '',
    width: '',
    height: '',
    weight: '',
    isFlatRate: true,
    flatRatePrice: 0,
    listed: false,
    categoryId: '',
    specifics: {},
    // New property for auction shipping option. If null, shipping is not applied.
    auctionShippingPrice: null,
  });

  // New state for auction shipping options
  const [auctionLocalPickupOnly, setAuctionLocalPickupOnly] = useState(true);
  const [auctionShippingPrice, setAuctionShippingPrice] = useState('');

  const specificsMapping = {
    Brand: 'brand',
    Model: 'model',
    UPC: 'upc',
    MPN: 'mpn',
    Type: 'type',
    Material: 'material',
    Color: 'color',
    Size: 'size',
    Height: 'height',
    Width: 'width',
    Depth: 'depth',
  };

  // Auto-fill required specifics from lotData
  useEffect(() => {
    if (requiredSpecifics.length > 0 && lotData) {
      setNewListing((prev) => {
        const updatedSpecifics = { ...prev.specifics };
        requiredSpecifics.forEach((specific) => {
          const detailKey = specificsMapping[specific];
          if (detailKey) {
            const detailValue = lotData.details ? lotData.details[detailKey] : null;
            if (detailValue && !updatedSpecifics[specific]) {
              updatedSpecifics[specific] = detailValue;
            }
          }
        });
        return {
          ...prev,
          specifics: updatedSpecifics,
        };
      });
    }
  }, [requiredSpecifics, lotData]);

  const handleCheckboxChange = () => {
    setNewListing((prev) => ({
      ...prev,
      isFlatRate: !prev.isFlatRate,
    }));
  };

  // Update newListing with auction shipping option based on auctionLocalPickupOnly state
  useEffect(() => {
    setNewListing((prev) => ({
      ...prev,
      auctionShippingPrice: auctionLocalPickupOnly ? null : auctionShippingPrice,
    }));
  }, [auctionLocalPickupOnly, auctionShippingPrice]);

  const handleListingAction = () => {
    setListingLoading(true);
    // Create the listing object with shipping details included (auctionShippingPrice will be null if Local Pickup Only)
    const listingToCreate = {
      ...newListing,
      // Optionally, you can also process auctionShippingPrice here
    };
    // Add listing creation logic here

    // After successful creation
    setListingLoading(false);
    toast.success('Listing created successfully!');
  };

  const images = lotData?.images?.map((src) => ({ src })) || [];

  if (!visible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose} />

      {/* Modal Content */}
      <div className="relative bg-white shadow-lg rounded-lg p-6 max-w-3xl mx-auto z-10">
        {/* Close Button */}
        <button
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
          onClick={onClose}
        >
          &times;
        </button>

        <div className="flex flex-col lg:flex-row lg:items-center">
          {/* Images */}
          <div className="flex gap-2 mb-4 lg:mb-0 lg:mr-6">
            {lotData.images.slice(0, 4).map((src, idx) => (
              <div
                key={idx}
                className="w-20 h-20 bg-gray-100 rounded overflow-hidden flex items-center justify-center cursor-pointer"
                onClick={() => {
                  setCurrImg(idx);
                  setViewerIsOpen(true);
                }}
              >
                <img
                  className="object-contain h-full"
                  src={
                    src.includes('gavelbase.s3')
                      ? src.replace('/images/', '/thumbnails/')
                      : src
                  }
                  alt={`Lot Image ${idx + 1}`}
                />
              </div>
            ))}
          </div>

          {/* Lot Details */}
          <div className="flex-1">
            <h2 className="text-lg font-semibold text-gray-800 mb-1">
              {lotData.title}
            </h2>
            <p className="text-sm text-gray-500 mb-2">
              Lot Number:{' '}
              <span className="font-medium">{lotData.lotNumber}</span>
            </p>
            <p className="text-sm text-gray-500">
              Model:{' '}
              <span className="font-medium">{lotData.details?.model}</span>
            </p>
            <p className="text-sm text-gray-500">
              Condition:{' '}
              <span className="font-medium">{lotData.condition?.name}</span>
            </p>
            <p className="text-sm text-gray-500">{lotData.conditionDescription}</p>
          </div>
        </div>

        {/* Listing Form */}
        <div className="mt-6 border-t pt-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Fixed Price
              </label>
              <TextInput
                prefix="$"
                value={newListing.fixedPrice}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '');
                  setNewListing((prev) => ({
                    ...prev,
                    fixedPrice: value,
                  }));
                }}
              />
            </div>

            {/* Dimensions and Weight */}
            {!newListing.isFlatRate && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Dimensions & Weight
                </label>
                <div className="flex space-x-2">
                  <TextInput
                    placeholder='L"'
                    value={newListing.length}
                    onChange={(e) =>
                      setNewListing({ ...newListing, length: e.target.value })
                    }
                  />
                  <TextInput
                    placeholder='W"'
                    value={newListing.width}
                    onChange={(e) =>
                      setNewListing({ ...newListing, width: e.target.value })
                    }
                  />
                  <TextInput
                    placeholder='H"'
                    value={newListing.height}
                    onChange={(e) =>
                      setNewListing({ ...newListing, height: e.target.value })
                    }
                  />
                  <TextInput
                    placeholder="LB"
                    value={newListing.weight}
                    onChange={(e) =>
                      setNewListing({ ...newListing, weight: e.target.value })
                    }
                  />
                </div>
              </div>
            )}

            {/* Flat Rate Price */}
            {newListing.isFlatRate && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Flat Rate Price
                </label>
                <TextInput
                  prefix="$"
                  value={newListing.flatRatePrice}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');
                    setNewListing((prev) => ({
                      ...prev,
                      flatRatePrice: value,
                    }));
                  }}
                />
              </div>
            )}

            <button
              type="button"
              disabled={listingLoading || newListing.listed}
              className={`inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm ${
                newListing.listed
                  ? 'bg-green-100 text-green-700'
                  : 'bg-blue-600 text-white'
              }`}
              onClick={handleListingAction}
            >
              {listingLoading ? <BarLoader color="white" /> : 'List'}
            </button>
          </div>
        </div>

        {/* Auction Shipping Options */}
        <div className="mt-6 border-t pt-6">
          <h3 className="text-lg font-medium text-gray-800 mb-2">
            Shipping Options
          </h3>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={auctionLocalPickupOnly}
              onChange={() =>
                setAuctionLocalPickupOnly(!auctionLocalPickupOnly)
              }
              className="form-checkbox"
            />
            <span className="ml-2">Local Pickup Only</span>
          </label>
          {!auctionLocalPickupOnly && (
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">
                Shipping Price
              </label>
              <TextInput
                prefix="$"
                value={auctionShippingPrice}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '');
                  setAuctionShippingPrice(value);
                }}
              />
            </div>
          )}
        </div>

        {/* Image Viewer */}
        {viewerIsOpen && (
          <ImgsViewer
            imgs={images}
            currImg={currImg}
            isOpen={viewerIsOpen}
            onClickPrev={() =>
              setCurrImg((currImg + images.length - 1) % images.length)
            }
            onClickNext={() => setCurrImg((currImg + 1) % images.length)}
            onClose={() => setViewerIsOpen(false)}
            showCloseBtn
            enableKeyboardInput
          />
        )}
      </div>
    </div>
  );
};

export default ListItemModal;
