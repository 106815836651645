import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axiosInstance from "../../axiosInstance";
import { toast } from "react-toastify";

// LotPickerModal props:
// - isOpen: boolean to show/hide the modal
// - onClose: function to call when modal closes; it receives the selected lot id(s)
// - multiSelect (optional): if true, allow multiple selection (default is false)
export default function LotPickerModal({ isOpen, onClose, multiSelect = false, className = "" }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [lots, setLots] = useState([]);
  const [searchText, setSearchText] = useState("");
  // Always store selected lots as an array; if multiSelect is false, only one element is allowed
  const [selectedLotIds, setSelectedLotIds] = useState([]);

  // Fetch lots based on searchText
  useEffect(() => {
    const fetchLots = async () => {
      setLoading(true);
      setError("");
      try {
        // Example: using POST with keyword-search API (adjust endpoint and params as needed)
        const response = await axiosInstance.post("/v1/crew/lot/keyword-search", {
          keyword: searchText,
          offset: 0,
          count: 50,
          sort: { createdAt: -1 },
          filters: {},
        });
        setLots(response.data.results || []);
      } catch (err) {
        setError(err.response?.data?.error || "Failed to fetch lots");
      } finally {
        setLoading(false);
      }
    };

    fetchLots();
  }, [searchText]);

  // Toggle selection of a lot
  const toggleSelection = (lotId) => {
    if (multiSelect) {
      if (selectedLotIds.includes(lotId)) {
        setSelectedLotIds(selectedLotIds.filter((id) => id !== lotId));
      } else {
        setSelectedLotIds([...selectedLotIds, lotId]);
      }
    } else {
      // For single selection, simply replace the selection
      setSelectedLotIds([lotId]);
    }
  };

  // Handle submit: close modal and return selected id(s)
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!multiSelect && selectedLotIds.length === 0) {
      toast.error("Please select a lot before submitting.");
      return;
    }
    onClose(multiSelect ? selectedLotIds : selectedLotIds[0] || null);
  };

  // Handle cancel: close without selection (return null)
  const handleCancel = () => {
    onClose(null);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={handleCancel}>
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
          </Transition.Child>

          {/* Centering trick */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block w-full max-w-lg p-6 overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${className}`}
              style={{ maxHeight: "calc(100vh - 2rem)" }}
            >
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Select Lot{multiSelect ? "s" : ""}
              </Dialog.Title>
              
              {/* Search Input */}
              <div className="mt-4 mb-2">
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search lots..."
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              {/* Lots List */}
              <div className="mt-2">
                {loading && (
                  <p className="text-center text-gray-600">Loading lots...</p>
                )}
                {error && (
                  <p className="text-center text-red-600">{error}</p>
                )}
                {!loading && !error && lots.length === 0 && (
                  <p className="text-center text-gray-600">No lots found.</p>
                )}
                <ul className="space-y-3 mt-4">
                  {lots.map((lot) => (
                    <li
                      key={lot._id}
                      onClick={() => toggleSelection(lot._id)}
                      className={`cursor-pointer p-3 border rounded-md flex items-center justify-between 
                        ${selectedLotIds.includes(lot._id) ? "bg-indigo-100 border-indigo-500" : "bg-white border-gray-200"}`}
                    >
                      <div>
                        <p className="font-medium text-gray-800">
                          {lot.lotNumber ? `Lot #${lot.lotNumber}` : "Untitled Lot"}
                        </p>
                        <p className="text-sm text-gray-600">{lot.title}</p>
                      </div>
                      {lot.images && lot.images.length > 0 ? (
                        <img
                          src={lot.images[0]}
                          alt={lot.title}
                          className="w-16 h-16 object-cover rounded-md"
                        />
                      ) : (
                        <div className="w-16 h-16 bg-gray-300 rounded-md flex items-center justify-center text-xs text-gray-600">
                          No Image
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Modal Footer: Submit & Cancel */}
              <div className="mt-6 flex justify-end gap-3">
                <button
                  onClick={handleCancel}
                  className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 text-sm font-medium"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-500 text-sm font-medium"
                >
                  Submit
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
