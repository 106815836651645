import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import { toast } from 'react-toastify';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import PaymentModal from '../../Components/PaymentModal/PaymentModal';

// Optional platform images if you have them:
import ebay from '../../Assets/Images/ebay_icon.svg';
import member from '../../Assets/Images/memberlogo.webp';

// Fallback for any lot image that isn't available
const FALLBACK_IMAGE =
  'https://via.placeholder.com/300x200?text=No+Image+Available';

// Utility for formatting prices (cents → USD)
const formatPrice = (amount) => {
  if (typeof amount !== 'number' || isNaN(amount)) return '$0.00';
  return (amount / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

const EditOrder = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  // Fetch the order on component mount
  useEffect(() => {
    if (!orderId) return;

    axiosInstance
      .get(`/v1/crew/order/getOrder/${orderId}`)
      .then((response) => {
        if (response.data.success) {
          setOrder(response.data.order);
        } else {
          toast.error(response.data.message || 'Failed to load order.');
        }
      })
      .catch((error) => {
        console.error('Error fetching order:', error);
        toast.error('Error fetching order data.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [orderId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-lg">Loading Order Details...</p>
      </div>
    );
  }

  if (!order) {
    return (
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Order Not Found</h1>
        <button
          onClick={() => navigate(-1)}
          className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
        >
          Go Back
        </button>
      </div>
    );
  }

  // Determine platform logo
  const platformLogo = order.platform === 'Ebay' ? ebay : member;

  // User info from the order
  const userInfo = order.user || null;

  // Calculate totals (order total and running total)
  const getOrderTotal = (order) => {
    const lineItemsSum = order.lineItems?.reduce((acc, lineItem) => {
      const itemTotal = lineItem.charges.reduce((sum, charge) => sum + charge.amount, 0);
      return acc + itemTotal;
    }, 0) || 0;
    const orderLedgerSum = order.orderLedger?.charges?.reduce((acc, charge) => acc + charge.amount, 0) || 0;
    return lineItemsSum + orderLedgerSum;
  };

  const orderTotalCents = getOrderTotal(order);
  const orderTotalDollars = (orderTotalCents / 100).toFixed(2);
  const paymentsTotalCents = order.payments?.reduce((acc, payment) => acc + payment.amount, 0) || 0;
  const paymentsTotalDollars = (paymentsTotalCents / 100).toFixed(2);
  // Running total: Order Total minus Payments Made (in dollars)
  const runningTotalDollars = ((orderTotalCents - paymentsTotalCents) / 100).toFixed(2);

  return (
    <div className="p-4 space-y-6">
      {/* Header / Basic Order Data */}
      <div className="bg-white p-4 rounded-md shadow-sm">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-4">
          <div>
            <h1 className="text-2xl font-bold flex items-center gap-2">
              Order{' '}
              <span
                className="inline-block px-2 py-1 bg-gray-100 rounded-md text-base text-gray-800 
                  cursor-pointer blur-sm hover:blur-none transition"
                title="Hover to reveal"
              >
                {order.orderCode}
              </span>
            </h1>
            <p className="text-gray-600">
              Status: <strong>{order.status}</strong>
            </p>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <img src={platformLogo} alt="Platform" className="w-8 h-8 object-contain" />
              <span className="text-sm text-gray-700">{order.platform}</span>
            </div>
            <button
              onClick={() => alert('Edit Order Status')}
              className="px-4 py-2 text-sm font-semibold bg-blue-600 text-white rounded hover:bg-blue-500"
            >
              Edit Status
            </button>
          </div>
        </div>

        {/* Basic Data (User Info, Shipping / Pickup) */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* User Info */}
          <div className="bg-gray-50 p-3 rounded-md border border-gray-200">
            <h2 className="font-semibold text-sm mb-1">Order Placed By</h2>
            {userInfo ? (
              <>
                <p className="text-sm">
                  {userInfo.firstName} {userInfo.lastName}
                </p>
                <p className="text-sm text-gray-600">{userInfo.email}</p>
                <p className="text-sm text-gray-600">{userInfo.username}</p>
              </>
            ) : (
              <p className="text-sm text-gray-500">No user info available.</p>
            )}
          </div>

          {/* Shipping Address */}
          <div className="bg-gray-50 p-3 rounded-md border border-gray-200">
            <h2 className="font-semibold text-sm mb-1">Shipping Address</h2>
            {order.shippingAddress?.name ? (
              <>
                <p className="text-sm">
                  <strong>Name:</strong> {order.shippingAddress.name}
                </p>
                <p className="text-sm">{order.shippingAddress.address?.street1}</p>
                {order.shippingAddress.address?.street2 && (
                  <p className="text-sm">{order.shippingAddress.address?.street2}</p>
                )}
                <p className="text-sm">
                  {order.shippingAddress.address?.city}, {order.shippingAddress.address?.state}{' '}
                  {order.shippingAddress.address?.zip}
                </p>
                <p className="text-sm">{order.shippingAddress.address?.country}</p>
              </>
            ) : (
              <p className="text-gray-500 text-sm">No shipping address.</p>
            )}
            <button
              onClick={() => alert('Edit Shipping Address')}
              className="mt-2 px-3 py-1 text-xs bg-indigo-600 text-white rounded hover:bg-indigo-500"
            >
              Edit
            </button>
          </div>

          {/* Pickup Method */}
          <div className="bg-gray-50 p-3 rounded-md border border-gray-200">
            <h2 className="font-semibold text-sm mb-1">Pickup Method</h2>
            <p className="text-sm">{order.pickupMethod || 'Not specified'}</p>
            <button
              onClick={() => alert('Change Pickup Method')}
              className="mt-2 px-3 py-1 text-xs bg-indigo-600 text-white rounded hover:bg-indigo-500"
            >
              Change
            </button>
          </div>
        </div>
      </div>

      {/* Line Items Section */}
      <div className="bg-white p-4 rounded-md shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <h2 className="font-semibold text-lg">Line Items</h2>
          <button
            onClick={() => alert('Add Line Item')}
            className="px-3 py-1 text-sm bg-green-600 text-white rounded hover:bg-green-500"
          >
            Add Line Item
          </button>
        </div>
        {order.lineItems && order.lineItems.length > 0 ? (
          <table className="w-full text-sm">
            <thead className="border-b bg-gray-50">
              <tr className="text-left text-gray-700">
                <th className="px-2 py-2">Lot</th>
                <th className="px-2 py-2">Title</th>
                <th className="px-2 py-2">Condition</th>
                <th className="px-2 py-2">Status</th>
                <th className="px-2 py-2">Location</th>
                <th className="px-2 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {order.lineItems.map((item, i) => {
                const lotImages = item.images && item.images.length > 0 ? item.images : [FALLBACK_IMAGE];
                const firstImage = lotImages[0];
                return (
                  <Fragment key={i}>
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <tr className="border-b">
                            <td className="px-2 py-3">
                              <div className="flex items-center gap-2">
                                <img src={firstImage} alt="Lot" className="w-10 h-10 object-cover rounded" />
                                <div className="text-sm font-medium">{item.lotNumber || 'N/A'}</div>
                              </div>
                            </td>
                            <td className="px-2 py-3">{item.title || 'Untitled'}</td>
                            <td className="px-2 py-3">{item.condition || 'N/A'}</td>
                            <td className="px-2 py-3">{item.status || 'N/A'}</td>
                            <td className="px-2 py-3">{item.warehouseLocation || 'Unknown'}</td>
                            <td className="px-2 py-3">
                              <div className="flex gap-2 items-center">
                                <button
                                  onClick={() => alert(`Edit Line Item #${item.lotNumber}`)}
                                  className="px-2 py-1 bg-indigo-600 text-white rounded hover:bg-indigo-500 text-xs"
                                >
                                  Edit
                                </button>
                                <Disclosure.Button
                                  className="flex items-center justify-center px-2 py-1 bg-gray-100 text-gray-600 rounded hover:bg-gray-200 hover:text-gray-800"
                                >
                                  <ChevronDownIcon
                                    className={`h-4 w-4 transform transition-transform duration-200 ${
                                      open ? 'rotate-180' : 'rotate-0'
                                    }`}
                                  />
                                </Disclosure.Button>
                                <button
                                  onClick={() => alert(`Remove Line Item #${item.lotNumber}`)}
                                  className="px-2 py-1 bg-red-600 text-white rounded hover:bg-red-500 text-xs"
                                >
                                  Remove
                                </button>
                              </div>
                            </td>
                          </tr>
                          <Disclosure.Panel as="tr" className="bg-gray-50">
                            <td colSpan={6} className="px-4 py-4 border-b border-gray-200 text-sm text-gray-700">
                              <div className="border border-gray-300 rounded-md p-3">
                                <h3 className="text-base font-semibold mb-2">Line Item Charges</h3>
                                {item.charges && item.charges.length > 0 ? (
                                  item.charges.map((c, idx) => (
                                    <div key={idx} className="flex items-center justify-between py-1">
                                      <span>{c.category}: {c.name}</span>
                                      <span>{formatPrice(c.amount)}</span>
                                    </div>
                                  ))
                                ) : (
                                  <p className="text-gray-500">No charges for this item.</p>
                                )}
                                <button
                                  onClick={() => alert(`Add charge to line item #${item.lotNumber}`)}
                                  className="mt-3 px-2 py-1 text-xs bg-blue-600 text-white rounded hover:bg-blue-500"
                                >
                                  Add Charge
                                </button>
                              </div>
                            </td>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="text-gray-500">No Line Items Found.</p>
        )}
      </div>

      {/* Order Charges (Order Ledger) */}
      <div className="bg-white p-4 rounded-md shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <h2 className="font-semibold text-lg">Order Charges</h2>
          <button
            onClick={() => alert('Add Order Charge')}
            className="px-3 py-1 text-sm bg-green-600 text-white rounded hover:bg-green-500"
          >
            Add Charge
          </button>
        </div>
        <div className="space-y-2">
          {order.orderLedger?.charges?.length > 0 ? (
            order.orderLedger.charges.map((charge, idx) => (
              <div key={idx} className="p-3 border border-gray-200 rounded-md flex items-center justify-between">
                <div>
                  <p className="text-sm">
                    <strong>{charge.category}</strong> - {charge.name}
                  </p>
                  <p className="text-sm">
                    Amount: <strong>{formatPrice(charge.amount)}</strong>
                  </p>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => alert('Edit Charge')}
                    className="px-2 py-1 text-sm bg-blue-50 text-blue-600 border border-blue-600 rounded hover:bg-blue-100"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => alert('Remove Charge')}
                    className="px-2 py-1 text-sm bg-red-50 text-red-600 border border-red-600 rounded hover:bg-red-100"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No Order-Level Charges Found.</p>
          )}
        </div>
      </div>

      {/* Payments Made Section */}
      <div className="bg-white p-4 rounded-md shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <h2 className="font-semibold text-lg">Payments</h2>
          <button
            onClick={() => setPaymentModalOpen(true)}
            className="px-3 py-1 text-sm bg-green-600 text-white rounded hover:bg-green-500"
          >
            Add Payment
          </button>
        </div>
        <div className="space-y-2">
          {order.payments && order.payments.length > 0 ? (
            order.payments.map((payment, index) => (
              <div key={index} className="p-3 border border-gray-200 rounded-md flex flex-col">
                <div className="flex justify-between text-sm">
                  <span>Method: <span className="font-medium">{payment.method}</span></span>
                  <span>{formatPrice(payment.amount)}</span>
                </div>
                <div className="flex justify-between text-xs text-gray-500">
                  <span>{new Date(payment.createdAt).toLocaleDateString()}</span>
                  <span>Status: {payment.status}</span>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No Payments Found.</p>
          )}
        </div>
      </div>

      {/* Totals Section */}
      <div className="bg-white p-4 rounded-md shadow-sm flex justify-between items-center">
        <div className="text-lg font-bold">Order Total: ${orderTotalDollars}</div>
        <div className="text-lg font-bold">Running Total: ${runningTotalDollars}</div>
      </div>

      {/* Back Button */}
      <div className="pt-2">
        <button
          onClick={() => navigate(-1)}
          className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
        >
          Go Back
        </button>
      </div>

      {/* Payment Modal */}
      {paymentModalOpen && (
        <PaymentModal
          isOpen={paymentModalOpen}
          onClose={(val) => {
            setPaymentModalOpen(val);
            // After closing PaymentModal, re-fetch order data to update payments, etc.
            axiosInstance.get(`/v1/crew/order/getOrder/${orderId}`)
              .then((response) => {
                if (response.data.success) {
                  setOrder(response.data.order);
                }
              })
              .catch((error) => {
                console.error('Error updating order after payment:', error);
              });
          }}
          orderId={orderId}
        />
      )}
    </div>
  );
};

export default EditOrder;
