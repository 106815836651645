// useLib.js

import axios from "axios";
import { toast } from 'react-toastify';
import {jwtDecode} from 'jwt-decode'; 
import JSZip from 'jszip'; // Import JSZip for ZIP creation
import { saveAs } from 'file-saver'; // Import FileSaver for download
import axiosInstance from "../axiosInstance";

// 1. createServerUrl(path) => returns the server url with the path appended from the env file
const createServerUrl = (path) => {
    return `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_DOMAIN}${process.env.REACT_APP_SERVER_PORT ? ':'+process.env.REACT_APP_SERVER_PORT : ''}${path}`;
};

// 2. getCookie(name) => returns the value of the cookie with the name
const getCookie = (name) => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());

    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=');
        if (cookieName === name) {
            return cookieValue;
        }
    }

    return null; // Return null if the cookie is not found
};

// 3. isLoggedIn() => returns a boolean value if the user is logged in (checks the refresh token expiration date)
const isLoggedIn = () => {
    const refreshToken = getCookie('refreshToken');
    if (refreshToken) {
        const decoded = jwtDecode(refreshToken);
        if (decoded) {
            if (decoded.exp * 1000 > Date.now()) {
                return false;
            } else {
                return true;
            }
        }
    }
    return false;
};

// 4. setCookie(name, value, days) => sets a cookie with the name, value, and days until expiration
const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
};

// 5. toast => the toast function from react-toastify (already imported above)

// 6. useNotification => gets and uses the notification object from the URL ?notification={severity: 'success', message: 'message'} then clears the URL
const useNotification = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const notification = urlParams.get('notification');
    if (notification) {
        let notificationObject = JSON.parse(notification);
        toast[notificationObject.severity](notificationObject.message);
        window.history.replaceState(null, null, window.location.pathname);

        return notificationObject;
    } else {
        return null;
    }
};

// 7. createNotification => creates a notification object to be used in the URL ?notification={severity: 'success', message: 'message'}
const createNotification = (severity, message) => {
    return `notification=${JSON.stringify({severity: severity, message: message})}`;
};

// 8. signOut() => signs the user out and redirects to the sign-in page
const signOut = () => {
    // Remove the cookies
    document.cookie = 'refreshToken=; expires = Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'accessToken=; expires = Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    window.location.href = '/sign-in';
};

// 9. fileToBase64(file) => converts an image or file to a base64 string
const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      // Use FileReader to convert the file to Base64
      const reader = new FileReader();
      reader.onloadend = () => {
        // The result attribute contains the data as a base64 encoded string
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(new Error('Failed to read file!'));
      };
      reader.readAsDataURL(file);
    });
};

// 10. uploadImages(files) => uploads an array of files to the server
const uploadImages = async (name, imageArray) => {
    try {
        // Axios request with the base64 images
        const response = await axios.post(createServerUrl('/api/v1/image/upload'), {
            company: "5dollarauctions",
            name: name,
            imageData: imageArray
        }, {
            withCredentials: true
        });

        return response.data;
    } catch (error) {
        console.error(error);
    }
};

// 11. createLot(lotObject) => creates a lot with the lotObject, sends the images to the server's S3 bucket and returns the response while creating the lot in MongoDB
const createLot = (lot) => {
    // Return a new Promise
    // if no images just create the lot without images
    if (lot.images.length === 0) {
        return new Promise((resolve, reject) => {
            axios.post(createServerUrl('/api/v1/lot/create'), lot, {
                withCredentials: true
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.log(error)
                reject(error.response.data)
            });
        });
    } else {
        return new Promise((resolve, reject) => {
            uploadImages(lot.lotNumber, lot.images)
                .then(response => {
                    // Check if all images were uploaded successfully
                    if (response) {
                        if (response.length !== lot.images.length) {
                            throw new Error('Image upload failed');
                        }
                    }
                    
                    // Update the lot's images with the response
                    lot.images = response;

                    // Proceed to create the lot with updated lot
                    return axios.post(createServerUrl('/api/v1/lot/create'), lot, {
                        withCredentials: true
                    })
                })
                .then(response => {
                    // Resolve the outer Promise with the response if lot creation is successful
                    resolve(response);
                })
                .catch(error => {
                    // Reject the outer Promise with a descriptive error message
                    if (error.message === 'Image upload failed') {
                        reject('Images failed to upload.');
                    } else {
                        // Sends the error code given by MongoDB to the front end
                        console.log(error)
                        reject(error.response.data)
                    }
                });
        });
    }
};

// Helper function to extract filename from URL
const extractFileName = (url, index) => {
    const originalName = url.substring(url.lastIndexOf('/') + 1) || `image_${index}.jpg`;
    return originalName.replace(/\s+/g, '_');
};

// Helper function to generate CSV content
const escapeAndQuote = (text) => {
    if (typeof text === 'string') {
        // Escape existing separators (pipes) by replacing them with two pipes (||)
        const escapedText = text.replace(/\|/g, '||');
        return `"${escapedText}"`; // Enclose in quotes to handle commas and special characters
    }
    return text ?? ''; // Return empty string for null/undefined
};

// Updated function to generate CSV content with unique separator
const generateCSV = (lots) => {
    const headers = [
        'Lot Number',
        'Title',
        'Description',
        'Condition',
        'Condition Description',
        'Model',
        'UPC',
        'Brand',
        'Category',
        'Status',
        'Created At',
        'Updated At',
        'Image URLs',
    ];

    // Map each lot to a CSV row with the new separator
    const rows = lots.map((lot) => [
        escapeAndQuote(lot.lotNumber),
        escapeAndQuote(lot.title),
        escapeAndQuote(lot.description),
        escapeAndQuote(lot.condition?.name || ''),
        escapeAndQuote(lot.conditionDescription || ''),
        escapeAndQuote(lot.details?.model || ''),
        escapeAndQuote(lot.details?.upc || ''),
        escapeAndQuote(lot.details?.brand || ''),
        escapeAndQuote(lot.category?.name || ''),
        escapeAndQuote(lot.status || ''),
        escapeAndQuote(new Date(lot.createdAt).toISOString() || ''),
        escapeAndQuote(new Date(lot.updatedAt).toISOString() || ''),
        escapeAndQuote((lot.images || []).join(' | ')), // Join multiple URLs with a single pipe
    ]);

    // Combine headers and rows into a CSV with the pipe separator
    const csv = [headers.join('|'), ...rows.map(row => row.join('|'))].join('\n');
    return csv;
};

// Download a single image with error handling
const downloadImage = async (url) => {
    try {
        const response = await axios.get(url, { responseType: 'blob' });
        return response.data;
    } catch (error) {
        console.warn(`Error downloading image from ${url}: ${error.message}`);
        return null;
    }
};

// Main function to download auction data
const downloadAuctionData = async (lotIds) => {
    try {
        // Fetch lot data
        const response = await axiosInstance.get('/v1/crew/lot/info', {
            params: { lotId: lotIds },
        });

        const lots = response.data.lots || (response.data.lot ? [response.data.lot] : []);
        if (lots.length === 0) {
            throw new Error('No lots found for the provided lotId(s).');
        }

        // Generate CSV content
        const csvContent = generateCSV(lots);
        const zip = new JSZip();
        zip.file('lots.csv', csvContent); // Add CSV to the ZIP

        // Download each image and add it to the ZIP
        for (const lot of lots) {
            if (lot.images && Array.isArray(lot.images)) {
                for (const [index, url] of lot.images.entries()) {
                    try {
                        const imageResponse = await fetch(url, { method: 'GET', mode: 'cors' });
                        if (!imageResponse.ok) {
                            console.warn(`Failed to download image: ${url} - Status: ${imageResponse.status}`);
                            continue;
                        }

                        const blob = await imageResponse.blob();
                        const fileName = `${lot.lotNumber || 'lot'}(${index + 1}).jpg`;
                        zip.folder('images').file(fileName, blob);
                    } catch (error) {
                        console.error(`Error downloading image from ${url}:`, error);
                    }
                }
            }
        }

        // Finalize ZIP and download
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, 'auction_data.zip');
        toast.success('ZIP file downloaded successfully!');

    } catch (error) {
        console.error('Error during download process:', error);
        toast.error('Failed to download ZIP file.');
    }
};

// 12. Add downloadAuctionData to useLib
const useLib = {
    createServerUrl,
    getCookie,
    setCookie,
    toast,
    useNotification,
    createNotification,
    signOut,
    fileToBase64,
    uploadImages,
    createLot,
    downloadAuctionData // Add the new function here
};

export default useLib;
