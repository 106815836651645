import React, { useState, useEffect } from 'react';
import { AgCharts } from 'ag-charts-react';
import { BarLoader } from 'react-spinners';
import axiosInstance from '../../axiosInstance';

function TodoStatistic() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        axiosInstance.get('/v1/crew/member/todo-statistics').then((response) => {
            let data = {
                lotsIdle: response.data.lotsIdle,
                ordersSold: response.data.ordersSold,
            };
            setData(data);
            setLoading(false);
        }).catch((error) => {
            setError(error.response?.data?.error || 'Failed to fetch data');
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <div className='bg-white shadow overflow-hidden rounded-lg flex items-center justify-center'>
                <BarLoader color='#2563EB' width={"100%"} />
            </div>
        );
    }

    if (error) {
        return (
            <div className='bg-white shadow overflow-hidden rounded-lg flex items-center justify-center'>
                <p className='text-red-500'>{error}</p>
            </div>
        );
    }

    const keyTitles = {
        lotsIdle: "Lots to List",
        ordersSold: "Orders to Pick",
    };

    const chartData = Object.keys(data).map((key) => ({
        category: keyTitles[key] || key,
        value: data[key],
    }));

    const chartOptions = {
        data: chartData,
        title: {
            text: "Things To Do",
        },
        theme: {
            overrides: {
                bar: {
                    series: {
                        strokeWidth: 2,
                        stroke: "transparent",
                        cornerRadius: 6,
                    },
                },
            },
        },
        series: [
            {
                type: "bar",
                direction: "horizontal",
                xKey: "category",
                yKey: "value",
                yName: "Value",
                label: {
                    enabled: true,
                },
            },
        ],
        axes: [
            {
                type: "category",
                position: "left",
                label: {
                    formatter: (params) => {
                        const category = params.value;
                        const dataPoint = chartData.find((d) => d.category === category);
                        return `${category}\n${dataPoint.value}`;
                    },
                    html: true, // Enable HTML formatting for bold text
                },
            },
            {
                type: "number",
                position: "bottom",
                gridLine: {
                    enabled: false,
                },
                label: {
                    enabled: false,
                },
            },
        ],
    };

    return (
        <div className='bg-white shadow overflow-hidden rounded-lg flex flex-col'>
            <AgCharts options={chartOptions} />
        </div>
    );
}

export default TodoStatistic;
