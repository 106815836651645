import React, { useState, useEffect, useCallback, useRef } from 'react';
import axiosInstance from '../../axiosInstance';
import TextInput from '../../Components/TextInput/TextInput';
import { toast } from 'react-toastify';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { BarLoader } from 'react-spinners';
import ImgsViewer from 'react-images-viewer'; // Import ImgsViewer component

const ListItem = ({ lotId, displayLot, onSubmit }) => {
  const [listingLoading, setListingLoading] = useState(false);
  const [lotDetails, setLotDetails] = useState(null);
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [requiredSpecifics, setRequiredSpecifics] = useState([]);

  const [viewerIsOpen, setViewerIsOpen] = useState(false); // State for viewer visibility
  const [currImg, setCurrImg] = useState(0); // State for current image index

  const [newListing, setNewListing] = useState({
    fixedPrice: '',
    length: '',
    width: '',
    height: '',
    weight: '',
    isFlatRate: true,
    flatRatePrice: 0,
    listed: false,
    categoryId: '',
    lotId: '',
    specifics: {}, // Object to hold specifics
  });

  // Ref to track if the component is mounted to prevent memory leaks
  const isMounted = useRef(true);

  // Mapping from required specifics to lot.details fields
  const specificsMapping = {
    Brand: 'brand',
    Model: 'model',
    UPC: 'upc',
    MPN: 'mpn',
    Type: 'type',
    Material: 'material',
    Color: 'color',
    Size: 'size',
    Height: 'height',
    Width: 'width',
    Depth: 'depth',
    // Add more mappings as needed
  };

  // Fetch lot details and category suggestions
  useEffect(() => {
    isMounted.current = true; // Component is mounted

    const fetchLotDetails = async () => {
      try {
        const response = await axiosInstance.get('/v1/crew/lot/info', {
          params: { lotId },
        });

        if (response.data.lot && isMounted.current) {
          setLotDetails(response.data.lot);
          setNewListing((prev) => ({
            ...prev,
            lotId: response.data.lot._id,
          }));
          return response.data.lot;
        }
      } catch (err) {
        console.error('Error fetching lot info:', err);
      }
    };

    const getCategorySuggestions = async (searchString) => {
      try {
        const response = await axiosInstance.post(
          '/v1/crew/external/ebay/getCategorySuggestions',
          { query: searchString }
        );

        if (response.data.suggestions && isMounted.current) {
          return response.data.suggestions;
        }
      } catch (err) {
        console.error('Error fetching category suggestions:', err);
      }
    };

    fetchLotDetails()
      .then((lot) => {
        if (lot) {
          getCategorySuggestions(lot.title)
            .then((categories) => {
              if (categories && isMounted.current) {
                setCategorySuggestions(categories);
                setNewListing((prev) => ({
                  ...prev,
                  categoryId: categories[0].categoryId,
                }));
              }
            })
            .catch((err) => {
              console.error('Error fetching category suggestions:', err);
            });
        }
      })
      .catch((err) => {
        console.error('Error fetching lot details:', err);
      });

    return () => {
      isMounted.current = false; // Cleanup on unmount
    };
  }, [lotId]);

  // Fetch required specifics based on categoryId
  useEffect(() => {
    isMounted.current = true;

    const fetchRequiredSpecifics = async () => {
      if (newListing.categoryId) {
        try {
          const response = await axiosInstance.post(
            '/v1/crew/external/ebay/getItemSpecifics',
            {
              categoryId: newListing.categoryId,
            }
          );

          if (response.data.requiredAspects && isMounted.current) {
            setRequiredSpecifics(response.data.requiredAspects);
          }
        } catch (err) {
          console.log('Error fetching required specifics:', err);
        }
      }
    };

    fetchRequiredSpecifics();

    return () => {
      isMounted.current = false;
    };
  }, [newListing.categoryId]);

  // Auto-fill required specifics from lotDetails
  useEffect(() => {
    if (requiredSpecifics.length > 0 && lotDetails && isMounted.current) {
      setNewListing((prev) => {
        const updatedSpecifics = { ...prev.specifics };
        requiredSpecifics.forEach((specific) => {
          const detailKey = specificsMapping[specific];
          if (detailKey) {
            // Check in lot.details
            const detailValue = lotDetails.details
              ? lotDetails.details[detailKey]
              : null;

            if (detailValue && !updatedSpecifics[specific]) {
              updatedSpecifics[specific] = detailValue;
            }
          }
        });
        return {
          ...prev,
          specifics: updatedSpecifics,
        };
      });
    }
  }, [requiredSpecifics, lotDetails]);

  const handleCheckboxChange = () => {
    setNewListing((prev) => ({
      ...prev,
      isFlatRate: !prev.isFlatRate,
    }));
  };

  const handleListingAction = () => {
    setListingLoading(true);
    axiosInstance
      .post('/v1/crew/listing/create', {
        lotId: newListing.lotId,
        fixedPrice: newListing.fixedPrice,
        categoryId: newListing.categoryId,
        specifics: newListing.specifics, // Include specifics object
        isFlatRate: newListing.isFlatRate,
        flatShippingCost: newListing.flatRatePrice,
        dimensions: newListing.isFlatRate
          ? null
          : {
              length: newListing.length,
              width: newListing.width,
              height: newListing.height,
              weight: newListing.weight,
            },
      })
      .then((response) => {
        if (isMounted.current) {
          setNewListing((prev) => ({ ...prev, listed: true }));
          setListingLoading(false);
          onSubmit && onSubmit(response.data);
          toast.success('Listing created successfully!');
        }
      })
      .catch((err) => {
        console.log(err);
        if (isMounted.current) {
          setListingLoading(false);
          toast.error('Failed to create listing.');
        }
      });
  };

  if (!lotDetails) {
    return (
      <div className="flex items-center justify-center h-40">
        <p className="text-gray-500">Loading lot details...</p>
      </div>
    );
  }

  // Prepare images for ImgsViewer
  const images = lotDetails.images.map((src) => ({
    src: src,
  }));

  {newListing.listed && <span className="text-green-700">Active</span>}

  return (
    <div className="container bg-white shadow rounded-lg p-6">
    {displayLot &&
      <div className="flex flex-col cq-lg:flex-row cq-lg:items-center">
        {/* Images */}
        <div className="flex gap-2 mb-4 cq-lg:mb-0 cq-lg:mr-6">
          {lotDetails.images.slice(0, 4).map((src, idx) => (
            <div
              key={idx}
              className="w-20 h-20 bg-gray-100 rounded overflow-hidden flex items-center justify-center cursor-pointer"
              onClick={() => {
                setCurrImg(idx);
                setViewerIsOpen(true);
              }}
            >
              <img
                className="object-contain h-full"
                src={
                  src.includes('gavelbase.s3')
                    ? src.replace('/images/', '/thumbnails/')
                    : src
                }
                alt={`Lot Image ${idx + 1}`}
              />
            </div>
          ))}
          <div className="w-20 h-20 bg-gray-100 rounded flex items-center justify-center text-gray-500 cursor-pointer">
            Edit
          </div>
        </div>

        {/* Lot Details */}
        <div className="flex-1">
          <h2 className="text-lg font-semibold text-gray-800 mb-1">
            {lotDetails.title}
          </h2>
          <p className="text-sm text-gray-500 mb-2">
            Lot Number: <span className="font-medium">{lotDetails.lotNumber}</span>
          </p>
          <p className="text-sm text-gray-500 mb-2">
            Model: <span className="font-medium">{lotDetails.details?.model}</span>
          </p>
          <p className="text-sm text-gray-500 mb-2">
            Condition:{' '}
            <span className="font-medium">{lotDetails.condition?.name}</span>
          </p>
          <p className="text-sm text-gray-500">{lotDetails.conditionDescription}</p>
          <div className="mt-3">
            {/* Optional Buttons (Commented Out) */}
            <button
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(
                    lotDetails.details?.model || lotDetails.title || ''
                );
                toast.info('Query copied to clipboard!');
              }}
              className="text-blue-600 hover:underline mr-4"
            >
              Copy
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                const ebaySoldUrl = `https://www.ebay.com/sch/i.html?_nkw=${encodeURIComponent(
                    lotDetails.title || ''
                )}&_ipg=100&LH_Sold=1`;
                window.open(
                  ebaySoldUrl,
                  '_blank',
                  'noopener,noreferrer,width=1200,height=600'
                );
              }}
              className="text-blue-600 hover:underline"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    }

      {/* Listing Form */}
      <div className={displayLot && "mt-6 border-t pt-6"}>
        <div className="grid grid-cols-1 cq-md:grid-cols-2 cq-lg:grid-cols-4 gap-6">
          {/* Category Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Category
            </label>
            <select
              value={newListing.categoryId}
              onChange={(e) => {
                const categoryId = e.target.value;
                setNewListing((prev) => ({ ...prev, categoryId }));
              }}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              {categorySuggestions.map((category) => (
                <option key={category.categoryId} value={category.categoryId}>
                  {category.categoryName}
                </option>
              ))}
            </select>
          </div>

          {/* Fixed Price */}
          <div>
            <TextInput
              label="Fixed Price"
              prefix="$"
              value={newListing.fixedPrice}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9.]/g, '');
                setNewListing((prev) => ({
                  ...prev,
                  fixedPrice: value,
                }));
              }}
            />
          </div>

          {/* Dimensions and Weight */}
          {!newListing.isFlatRate && (
            <div>
              <div className="flex items-center justify-between mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Dimensions & Weight
                </label>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={!newListing.isFlatRate}
                    onChange={handleCheckboxChange}
                  />
                  <label className="ml-2 block text-sm font-medium text-gray-700">
                    Calculated Shipping
                  </label>
                </div>
              </div>

              <div className="flex space-x-2">
                <TextInput
                  placeholder='L"'
                  value={newListing.length}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');
                    setNewListing((prev) => ({
                      ...prev,
                      length: value,
                    }));
                  }}
                />
                <TextInput
                  placeholder='W"'
                  value={newListing.width}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');
                    setNewListing((prev) => ({
                      ...prev,
                      width: value,
                    }));
                  }}
                />
                <TextInput
                  placeholder='H"'
                  value={newListing.height}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');
                    setNewListing((prev) => ({
                      ...prev,
                      height: value,
                    }));
                  }}
                />
                <TextInput
                  placeholder="LB"
                  value={newListing.weight}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, '');
                    setNewListing((prev) => ({
                      ...prev,
                      weight: value,
                    }));
                  }}
                />
              </div>
            </div>
          )}

          {/* Flat Rate Shipping */}
          {newListing.isFlatRate && (
            <div>
              <div className="flex items-center justify-between mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  {`Flat Rate Price${
                    newListing.flatRatePrice === 0 ? ' (Free)' : ''
                  }`}
                </label>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={!newListing.isFlatRate}
                    onChange={handleCheckboxChange}
                  />
                  <label className="ml-2 block text-sm font-medium text-gray-700">
                    Calculated Shipping
                  </label>
                </div>
              </div>

              <TextInput
                prefix="$"
                value={newListing.flatRatePrice}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '');
                  setNewListing((prev) => ({
                    ...prev,
                    flatRatePrice: value,
                  }));
                }}
              />
            </div>
          )}

          <button
            type="button"
            disabled={listingLoading || newListing.listed}
            className={`inline-flex items-center px-6 text-xl py-2 border border-transparent text-sm font-medium rounded-md shadow-sm ${
              newListing.listed && !listingLoading
                ? 'bg-green-100 text-green-700 cursor-not-allowed'
                : 'bg-blue-600 text-white hover:bg-blue-700'
            }`}
            onClick={handleListingAction}
          >
            {listingLoading && (
              <BarLoader color="white" loading={listingLoading} size={150} />
            )}
            {newListing.listed && !listingLoading && (
              <span className="text-green-700">Active</span>
            )}
            {!newListing.listed && !listingLoading && <span>List</span>}
          </button>
        </div>

        {/* Item Specifics Disclosure */}
        {requiredSpecifics.length > 0 && (
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-6 flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-100 ring-1 ring-gray-400 rounded-lg hover:bg-gray-200 focus:outline-none">
                  <span
                    className={`${
                      Object.keys(newListing.specifics).length !==
                      requiredSpecifics.length
                        ? 'text-red-500'
                        : ''
                    }`}
                  >
                    {Object.keys(newListing.specifics).length}/
                    {requiredSpecifics.length}
                  </span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-gray-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                  <div className="grid grid-cols-1 cq-md:grid-cols-2 cq-lg:grid-cols-4 gap-6">
                    {requiredSpecifics.map((specific, index) => (
                      <TextInput
                        key={index}
                        label={specific}
                        value={newListing.specifics[specific] || ''}
                        onChange={(e) => {
                          const value = e.target.value;
                          setNewListing((prev) => ({
                            ...prev,
                            specifics: {
                              ...prev.specifics,
                              [specific]: value,
                            },
                          }));
                        }}
                      />
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        )}
      </div>

      {/* Image Viewer */}
      {viewerIsOpen && (
        <ImgsViewer
          imgs={images}
          currImg={currImg}
          isOpen={viewerIsOpen}
          onClickPrev={() =>
            setCurrImg((currImg + images.length - 1) % images.length)
          }
          onClickNext={() => setCurrImg((currImg + 1) % images.length)}
          onClose={() => setViewerIsOpen(false)}
          showThumbnails={true} // Optional: show thumbnails
          backdropCloseable={true} // Optional: allow closing by clicking backdrop
          spinnerDisabled={false}
          showCloseBtn={true}
          enableKeyboardInput={true}
        />
      )}
    </div>
  );
};

export default ListItem;
