import React, { useState, useRef, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { createPortal } from 'react-dom';
import { ChevronDown } from 'lucide-react';

const Button = ({
  size = 'md',
  text = '',
  icon: Icon,
  dropdownOptions = [],
  onClick,
  className = '',
  ...rest
}) => {
  const sizes = {
    xs: 'px-2 py-1 text-xs',
    sm: 'px-2.5 py-1.5 text-sm',
    md: 'px-3 py-2',
    lg: 'px-4 py-2 text-lg',
  };

  const heightClass = sizes[size];
  const buttonRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (isDropdownOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const dropdownWidth = 200; // Set dropdown width for calculation
      const dropdownHeight = dropdownOptions.length * 40; // Approximate height per item

      // Calculate positioning
      let top = rect.bottom + window.scrollY;
      let left = rect.left + window.scrollX + rect.width / 2 - dropdownWidth / 2;

      // Adjust if it overflows off the screen
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Horizontal bounds
      if (left + dropdownWidth > viewportWidth) {
        left = viewportWidth - dropdownWidth - 8; // Add some padding
      } else if (left < 0) {
        left = 8; // Add some padding
      }

      // Vertical bounds
      if (top + dropdownHeight > viewportHeight) {
        top = rect.top + window.scrollY - dropdownHeight; // Flip above the button
      }

      setDropdownPosition({ top, left, width: dropdownWidth });
    }

    const handleScroll = () => {
      if (isDropdownOpen) {
        closeDropdown();
      }
    };

    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [isDropdownOpen, buttonRef, dropdownOptions]);

  const DropdownPortal = ({ children }) => {
    return createPortal(children, document.body);
  };

  return (
    <div className="relative inline-flex">
      {/* Main Button */}
      <button
        ref={buttonRef}
        type="button"
        className={`flex items-center justify-center gap-x-1.5 bg-indigo-600 text-white font-semibold shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${heightClass} ${className} ${
          dropdownOptions.length > 0 ? 'rounded-l-md rounded-r-none' : 'rounded-md'
        }`}
        onClick={onClick}
        {...rest}
      >
        {Icon && <Icon className="h-5 w-5" aria-hidden="true" />}
        <span>{text}</span>
      </button>

      {/* Dropdown Button */}
      {dropdownOptions.length > 0 && (
        <Menu as="div" className="relative">
          <Menu.Button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className={`flex items-center justify-center bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-r-md ${heightClass}`}
          >
            <ChevronDown className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            show={isDropdownOpen}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <DropdownPortal>
              <Menu.Items
                className="fixed z-50 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden"
                style={{
                  position: 'absolute',
                  top: dropdownPosition.top,
                  left: dropdownPosition.left,
                  width: `${dropdownPosition.width}px`,
                }}
              >
                {dropdownOptions.map((option, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <button
                        onClick={option.onClick}
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } block w-full px-4 py-2 text-sm text-gray-700 text-left`}
                      >
                        {option.label}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </DropdownPortal>
          </Transition>
        </Menu>
      )}
    </div>
  );
};

export default Button;
