import { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { PencilIcon } from 'lucide-react';
import TagSelect from '../TagSelect/TagSelect'; // Adjust the path as necessary

const Tags = ({ tags, editable = true }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTags, setSelectedTags] = useState(tags);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleTagChange = (newTags) => {
        setSelectedTags(newTags);
    };

    return (
        <div className='flex flex-wrap items-center h-full gap-2'>
            {editable && (
                <button onClick={openModal} className='absolute flex items-center justify-center top-1 right-1 rounded-full h-6 w-6 bg-gray-100 hover:bg-gray-200 focus:outline-none'>
                    <PencilIcon className='h-4 w-4' />
                </button>
            )}
            <div className='flex flex-wrap items-center gap-2'>
                
                {selectedTags.map((tag, index) => (
                    <div
                        key={index}
                        className='flex rounded-full py-1 px-2 justify-center text-xs'
                        style={{ color: tag.color, backgroundColor: tag.backgroundColor }}
                    >
                        {tag.name}
                    </div>
                ))}
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as='div' className='relative z-10' onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-black bg-opacity-25' />
                    </Transition.Child>

                    <div className='fixed inset-0 overflow-y-auto'>
                        <div className='flex min-h-full items-center justify-center p-4 text-center'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 scale-95'
                                enterTo='opacity-100 scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 scale-100'
                                leaveTo='opacity-0 scale-95'
                            >
                                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                                    <Dialog.Title
                                        as='h3'
                                        className='text-lg font-medium leading-6 text-gray-900'
                                    >
                                        Edit Tags
                                    </Dialog.Title>
                                    <div className='mt-2'>
                                        <TagSelect
                                            label="Select Tags"
                                            placeholder="Select tags"
                                            onChange={handleTagChange}
                                            selectedTags={selectedTags}
                                        />
                                    </div>

                                    <div className='mt-4'>
                                        <button
                                            type='button'
                                            className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                                            onClick={closeModal}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default Tags;