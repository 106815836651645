import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BarLoader } from "react-spinners";
import axiosInstance from "../../axiosInstance";

export default function OfferModal({ isOpen, onClose, className = "" }) {
  // Global states for fetching the list of offers
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [offers, setOffers] = useState([]);

  // New state to track the loading status and result of each offer action.
  // Structure: { [offerId]: { loading: boolean, result?: "Accepted" | "Rejected" | "Error" } }
  const [offerActions, setOfferActions] = useState({});

  // When the modal opens, fetch offers.
  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      setError("");
      axiosInstance
        .get(`/v1/crew/offer/`)
        .then((response) => {
          setOffers(response.data.offers || []);
          setLoading(false);
          // Clear any previous per-offer action state.
          setOfferActions({});
        })
        .catch((err) => {
          setError(err.response?.data?.error || "Failed to fetch data");
          setLoading(false);
        });
    } else {
      // Clear out the offers and error state when the modal closes.
      setOffers([]);
      setError("");
      setLoading(false);
      setOfferActions({});
    }
  }, [isOpen]);

  // Handle Accept action for a single offer
  const handleAccept = (offerId) => {
    // Mark this offer as loading.
    setOfferActions((prev) => ({
      ...prev,
      [offerId]: { loading: true },
    }));

    axiosInstance
      .get(`/v1/crew/offer/accept-offer/${offerId}`)
      .then(() => {
        // When finished, update the status to "Accepted".
        setOfferActions((prev) => ({
          ...prev,
          [offerId]: { loading: false, result: "Accepted" },
        }));
      })
      .catch((err) => {
        // If there's an error, you might choose to display it.
        setOfferActions((prev) => ({
          ...prev,
          [offerId]: { loading: false, result: "Error" },
        }));
      });
  };

  // Handle Reject action for a single offer
  const handleReject = (offerId) => {
    // Mark this offer as loading.
    setOfferActions((prev) => ({
      ...prev,
      [offerId]: { loading: true },
    }));

    axiosInstance
      .get(`/v1/crew/offer/reject-offer/${offerId}`)
      .then(() => {
        // When finished, update the status to "Rejected".
        setOfferActions((prev) => ({
          ...prev,
          [offerId]: { loading: false, result: "Rejected" },
        }));
      })
      .catch((err) => {
        setOfferActions((prev) => ({
          ...prev,
          [offerId]: { loading: false, result: "Error" },
        }));
      });
  };

  return (
    // The "appear" prop ensures the transition plays on initial mount too.
    <Transition.Root show={isOpen} as={Fragment} appear>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* Centering element */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block w-full max-w-lg p-6 overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl ${className}`}
              style={{ maxHeight: "80vh" }}
            >
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Pending Offers
              </Dialog.Title>
              <div className="mt-2 text-sm text-gray-700">
                {loading && (
                  <div className="flex min-h-32 items-center p-4 gap-8 rounded-lg">
                    <BarLoader color="#2563EB" width={"100%"} />
                  </div>
                )}

                {error && (
                  <div className="flex min-h-32 p-4 gap-8 rounded-lg">
                    <p className="text-red-500">{error}</p>
                  </div>
                )}

                {!loading && !error && offers.length > 0 && (
                  <ul className="space-y-4 mt-4">
                    {offers.map((offer) => {
                      const lotTitle = offer.lotId?.title || "Untitled Lot";
                      const thumbnailUrl = offer.lotId?.images?.[0] || "";
                      const offerDateTime = new Date(offer.createdAt).toLocaleString();

                      // Check if there is an action status for this offer.
                      const action = offerActions[offer._id] || {};

                      return (
                        <li
                          key={offer._id}
                          className="p-3 border border-gray-200 rounded-md flex items-center justify-between"
                        >
                          <div className="flex items-center gap-3">
                            {thumbnailUrl ? (
                              <img
                                src={thumbnailUrl}
                                alt="Lot Thumbnail"
                                className="w-16 h-16 object-cover rounded-md"
                              />
                            ) : (
                              <div className="w-16 h-16 bg-gray-300 rounded-md flex items-center justify-center text-gray-600 text-sm">
                                No Image
                              </div>
                            )}
                            <div>
                              <p className="font-medium text-gray-800">{lotTitle}</p>
                              <p className="text-sm text-gray-500">
                                Offer:{" "}
                                {(offer.offerAmount / 100).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </p>
                              <p className="text-xs text-gray-500">Made on: {offerDateTime}</p>
                            </div>
                          </div>
                          <div className="flex gap-2">
                            {action.loading ? (
                              // Show a small loader in place of the buttons.
                              <div className="flex items-center">
                                <BarLoader color="#2563EB" width={80} />
                              </div>
                            ) : action.result ? (
                              // Display the final result ("Accepted", "Rejected", or "Error").
                              <span className="px-3 py-1 text-sm font-medium text-gray-800">
                                {action.result}
                              </span>
                            ) : (
                              // Otherwise, display the Accept and Reject buttons.
                              <>
                                <button
                                  onClick={() => handleAccept(offer._id)}
                                  className="px-3 py-1 text-sm bg-green-600 text-white rounded hover:bg-green-700"
                                >
                                  Accept
                                </button>
                                <button
                                  onClick={() => handleReject(offer._id)}
                                  className="px-3 py-1 text-sm bg-red-600 text-white rounded hover:bg-red-700"
                                >
                                  Reject
                                </button>
                              </>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}

                {!loading && !error && offers.length === 0 && (
                  <p className="mt-4 text-gray-500">No pending offers were found.</p>
                )}
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
