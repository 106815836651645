import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import axiosInstance from '../../axiosInstance';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import Button from '../../Components/Button/Button';
import DropdownButton from '../../Components/DropdownButton/DropdownButton';
import { Search, X } from 'lucide-react';

export default function ViewCustomers() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize state from URL parameters or set defaults
  const [page, setPage] = useState(() => {
    const pageParam = parseInt(searchParams.get('page'), 10);
    return !isNaN(pageParam) && pageParam > 0 ? pageParam - 1 : 0;
  });

  const [pageSize, setPageSize] = useState(() => {
    const pageSizeParam = parseInt(searchParams.get('pageSize'), 10);
    return !isNaN(pageSizeParam) ? pageSizeParam : 25;
  });

  const [searchText, setSearchText] = useState(() => searchParams.get('searchText') || '');
  const [rowData, setRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  // Sync state with URL parameters
  useEffect(() => {
    const params = new URLSearchParams();
    params.set('page', (page + 1).toString());
    params.set('pageSize', pageSize.toString());
    if (searchText) {
      params.set('searchText', searchText);
    } else {
      params.delete('searchText');
    }
    setSearchParams(params, { replace: true });
  }, [page, pageSize, searchText, setSearchParams]);

  // Fetch data from API
  const fetchData = useCallback(async () => {
    setLoading(true);
    const offset = page * pageSize;
    const count = pageSize;

    try {
      const response = await axiosInstance.post('/v1/crew/user/keyword-search', { // Adjust the endpoint as necessary
        keyword: searchText,
        offset,
        count,
        sort: {}, // Empty sort object
        filters: {}, // Empty filters
      });
      console.log(response);

      if (response.status === 200) {
        if (isMounted.current) {
          setRowData(response.data.results);
          setTotalCount(response.data.totalCount); // Assuming 'totalCount' is returned
        }
      } else {
        if (isMounted.current) setRowData([]);
      }
    } catch (error) {
      if (isMounted.current) setRowData([]);
      console.error('Error fetching data:', error);
    } finally {
      if (isMounted.current) setLoading(false);
    }
  }, [page, pageSize, searchText]);

  // Fetch data on component mount and when dependencies change
  useEffect(() => {
    isMounted.current = true;
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [fetchData]);

  // Define column definitions
  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'fullName',
        minWidth: 150,
        flex: 2,
        cellRenderer: (params) => (
          <div className="flex flex-col">
            <p className="font-bold">{`${params.data.firstName} ${params.data.lastName}`}</p>
            <p className="text-sm text-gray-600">{params.data.email}</p>
          </div>
        ),
      },
      {
        headerName: 'Username',
        field: 'username',
        flex: 1,
        minWidth: 150,
      },
      {
        headerName: 'Email',
        field: 'email',
        flex: 1,
        minWidth: 150,
      },
      {
        headerName: 'Verified',
        field: 'verified',
        flex: 1,
        minWidth: 150,
        cellRenderer: (params) => (
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${params.data.verified ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
            {params.data.verified ? 'Verified' : 'Unverified'}
          </span>
        ),
      },
      {
        headerName: 'Last Login',
        field: 'analytics',
        flex: 1,
        minWidth: 150,
        valueFormatter: (params) => {
          if (params.data.analytics?.lastLogin) {
            return new Date(params.data.analytics.lastLogin).toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            });
          }
          return 'Never';
        },
      },
      {
        headerName: 'Created At',
        field: 'createdAt',
        flex: 1,
        minWidth: 180,
        valueFormatter: (params) =>
          new Date(params.value).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }),
      },
      {
        headerName: 'Actions',
        field: 'actions',
        sortable: false,
        cellStyle: { overflow: 'visible' },
        cellRenderer: (params) => (
          <div className="flex items-center justify-end h-full space-x-2">
            {/* <Button */}
            <DropdownButton
              text="View"
              size="sm"
              className="bg-indigo-600 text-white font-semibold shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md"
              dropdownOptions={[
                { label: 'Edit', onClick: () => navigate(`/customers/edit/${params.data.id}`) },
                { label: 'Delete', onClick: () => handleDelete(params.data.id) }, // Implement handleDelete
              ]}
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  // Define grid options
  const gridOptions = useMemo(
    () => ({
      columnDefs,
      rowData,
      pagination: false,
      enableCellTextSelection: true,
      domLayout: 'autoHeight', // Ensures the grid adjusts its height based on content
      suppressRowTransform: true, // Helps with dynamic row heights
      suppressSorting: true, // Disable sorting
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading...</span>',
      overlayNoRowsTemplate: loading
        ? '<span class="ag-overlay-loading-center">Loading...</span>'
        : '<span class="ag-overlay-no-rows-center">No customers found</span>',
      defaultColDef: {
        resizable: true, // Allows columns to be resized
        wrapText: true, // Ensures text wraps in all columns
        autoHeight: false, // Only enable autoHeight on specific columns
      },
    }),
    [columnDefs, rowData, loading]
  );

  // Handle search button click
  const handleSearch = () => {
    setPage(0);
    fetchData();
  };

  // Handle clear search button click
  const handleClearSearch = () => {
    setSearchText('');
    setPage(0);
    fetchData();
  };

  // Handle customer deletion (Example Implementation)
  const handleDelete = async (customerId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this customer?');
    if (!confirmDelete) return;

    try {
      await axiosInstance.delete(`/v1/crew/user/${customerId}`); // Adjust endpoint as necessary
      fetchData(); // Refresh data after deletion
    } catch (error) {
      console.error('Error deleting customer:', error);
      // Optionally, show a notification to the user
    }
  };

  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <div className="flex flex-col h-full bg-gray-50 min-h-screen">

      {/* Header Section */}
      <div className="bg-white shadow border-b p-6">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Customers
            </h2>
          </div>
          <div className="mt-5 flex lg:ml-4 lg:mt-0 space-x-4">
            {/* Search Input */}
            <div className="relative">
              <input
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search customers"
                className="block w-full rounded-md border-gray-300 pl-10 pr-3 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
            </div>
            {/* Search and Clear Buttons */}
            <Button onClick={handleSearch} variant="primary">
              Search
            </Button>
            <Button onClick={handleClearSearch} variant="secondary">
              <X className="h-5 w-5" />
            </Button>
          </div>
        </div>
      </div>

      {/* Customers Grid Section */}
      <div className="flex flex-col flex-1">
        <div
          className="ag-theme-material w-full"
          style={{ flex: '1 1 auto', overflow: 'visible' }} // Changed 'hidden' to 'visible'
        >
          <AgGridReact {...gridOptions} />
        </div>
        {loading && <Loading />}
        {/* Pagination Controls */}
        <div className="flex justify-between items-center p-3">
          <span className="text-sm text-gray-700">
            Page {page + 1} of {totalPages}
          </span>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => setPage(Math.max(page - 1, 0))}
              disabled={page === 0}
              className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                page === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-500'
              }`}
            >
              Previous
            </button>
            <button
              onClick={() => setPage(Math.min(page + 1, totalPages - 1))}
              disabled={page + 1 >= totalPages}
              className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                page + 1 >= totalPages
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-500'
              }`}
            >
              Next
            </button>
            <select
              value={pageSize}
              onChange={(e) => { setPageSize(Number(e.target.value)); setPage(0); fetchData(); }}
              className="rounded-md border border-gray-300 bg-white py-2 px-3 pr-8 text-sm shadow-sm"
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  {size} / page
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
