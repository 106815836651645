// src/pages/VerifySMS.jsx

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading/Loading';
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

export default function VerifyPhone() {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('Verifying your phone number...');
  const [error, setError] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const location = useLocation();

  useEffect(() => {
    document.title = 'Verify Phone - Gavelbase';

    const verifyPhone = async (token) => {
      try {
        const response = await axios.post('https://api.gavelbase.com/v1/public/user/verify-phone', { token });
        if (response.status === 200) {
          setMessage('Phone number verified successfully! You can now use SMS features. Feel free to close this page.');
          setVerificationStatus('success');
        } else {
          setError('Verification failed. Please try again later.');
          setVerificationStatus('failed');
        }
      } catch (err) {
        setError(err?.response?.data?.message || 'Error verifying phone. Please try again.');
        setVerificationStatus('failed');
      } finally {
        setLoading(false);
      }
    };

    // Extract token from URL ?token=<TOKEN>
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token) {
      verifyPhone(token);
    } else {
      setVerificationStatus('failed');
      setError('Invalid verification link. Missing token.');
      setLoading(false);
    }
  }, [location.search]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              <div className="flex justify-center items-center space-x-2">
                {verificationStatus === 'failed' ? (
                  <>
                    <XCircleIcon className="h-6 w-6 text-red-500" />
                    <span>Verification Failed</span>
                  </>
                ) : (
                  <>
                    <CheckCircleIcon className="h-6 w-6 text-green-500" />
                    <span>Phone Verified</span>
                  </>
                )}
              </div>
            </h2>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="text-center text-gray-700">
              {verificationStatus === 'failed' ? (
                <p className="text-red-500">{error}</p>
              ) : (
                <p>{message}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
