import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import { toast } from 'react-toastify';
import { BarLoader } from 'react-spinners';

const OverviewStatistic = ({ className = "", label, timeFrame }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!loading) {
            setLoading(true);
        }
        axiosInstance.get(`/v1/crew/order/sales-statistics/${timeFrame}`).then((response) => {
            setData(response.data);
            setLoading(false);
        }).catch((error) => {
            setError(error.response?.data?.error || 'Failed to fetch data');
            setLoading(false);
        });
    }, [timeFrame]);

    // Loading state - maintain consistent height
    if (loading) {
        return (
            <div className={`flex p-4 min-h-32 items-center bg-white gap-4 rounded-lg ${className} min-h-[200px]`}>
                <BarLoader color='#2563EB' width={"100%"} />
            </div>
        );
    }

    // Error state
    if (error) {
        return (
            <div className={`flex p-4 min-h-32 bg-white gap-4 rounded-lg ${className} min-h-[200px]`}>
                <p className='text-red-500'>{error}</p>
            </div>
        );
    }

    // Loaded state
    return (
        <div className={`flex flex-col min-h-32 p-4 bg-white shadow rounded-lg ${className}`}>
            <div>
                <p className='text-lg leading-tight font-semibold'>{label}</p>
            </div>

            <div className='flex gap-4 flex-wrap justify-around'>
                <div className='flex flex-col'>
                    <p className='text-md text-gray-500'>Net Total</p>
                    <p className='text-5xl font-semibold'>${data?.netEarnings.toLocaleString()}</p>
                </div>
                <div className='flex flex-col'>
                    <p className='text-md text-gray-500'>Sold Lots</p>
                    <p className='text-5xl font-semibold'>{data?.lotCount.toLocaleString()}</p>
                </div>
            </div>
        </div>
    );
};

export default OverviewStatistic;
