import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Button from "../../Components/Button/Button";
import axiosInstance from "../../axiosInstance";
import { toast } from "react-toastify";

export default function PaymentModal({ isOpen, onClose = ()=>{}, orderId }) {
    const [amount, setAmount] = useState(0);
    const [method, setMethod] = useState("Credit Card");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const closeModal = () => {
        onClose(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (amount <= 0) {
            toast.error("Please enter a valid amount.");
            return;
        }

        setIsSubmitting(true);

        try {
            await axiosInstance.post(`/v1/orders/${orderId}/add-payment`, {
                amount: parseInt(amount, 10),
                method,
            });
            toast.success("Payment added successfully!");
            onClose(false);
        } catch (error) {
            toast.error(error.response?.data || "Failed to add payment.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
                    </Transition.Child>

                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                Add Payment for Order #{orderId}
                            </Dialog.Title>
                            <form onSubmit={handleSubmit} className="mt-4">
                                <div className="mb-4">
                                    <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                                        Amount (in cents)
                                    </label>
                                    <input
                                        id="amount"
                                        type="number"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                        placeholder="Enter amount"
                                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="method" className="block text-sm font-medium text-gray-700">
                                        Payment Method
                                    </label>
                                    <select
                                        id="method"
                                        value={method}
                                        onChange={(e) => setMethod(e.target.value)}
                                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    >
                                        <option value="Cash">Cash</option>
                                        <option value="Stripe">Credit Card</option>
                                    </select>
                                </div>
                                <div className="flex justify-end">
                                    <Button variant="secondary" onClick={closeModal} className="mr-2">
                                        Cancel
                                    </Button>
                                    <Button type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? "Submitting..." : "Submit Payment"}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}
